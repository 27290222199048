import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {KeyboardDatePicker} from '@material-ui/pickers';
import {format, setHours} from 'date-fns';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import {loadDonations, exportDonations} from "../../redux/reports/actions";
import {formatMoneyFromPennies} from "../../utils/money";

import './index.css';

const FIELDS = [
    {label: "Date Created", format: record => format(new Date(record.createdTimestamp), "MM/dd/yyyy")},
    {label: "Time Created", format: record => format(new Date(record.createdTimestamp), "HH:mm:ss.SSSxxx"), hidden: true},
    {label: "FormID", key: "formId"},
    {label: "Parent FormID", key: "parentFormId", hidden: true},
    {label: "Supporter FirstName", format: record => record.data.firstName},
    {label: "Supporter LastName", format: record => record.data.lastName},
    {label: "Address", format: record => record.data.streetAddress},
    {label: "Zip", format: record => record.data.zip},
    {label: "Email", key: "email"},
    {label: "Total Amount", format: record => formatMoneyFromPennies(parseInt(record.amount, 10)) },
    {label: "Monthly", format: record => record.charge ? "No" : "Yes" },
    {label: "Field Canvasser", format: record => record.data.canvasserName },
    {label: "Financial Type", format: record => record.isFund ? "TCE Fund" : "TCE" }
];

const Donations = () => {
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(setHours(new Date(), 0));
    const [endDate, setEndDate] = useState(setHours(new Date(), 23));
    const [records, setRecords] = useState(null);
    const [exporting, setExporting] = useState(false);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const onSearch = () => {
        setLoading(true);
        dispatch(loadDonations(startDate, endDate, results => {
            console.log("results", results);
            setRecords(results);
            setLoading(false);
        }))
    };

    const exportRecords = () => {
        const exportRecords = records.map(record => FIELDS.map(field => field.key ? record[field.key] : field.format(record)));
        setExporting(true);
        dispatch(exportDonations(exportRecords, setExporting, enqueueSnackbar));
    };

    return (
        <div className="reports-container padding30">
            <Card className="padding20">
                <CardContent>
                    <div className="text-align-left">
                        <div className="page-header">Export Donations</div>
                        <div>
                            Select a date range and export.
                        </div>
                    </div>
                    <div className="flex marginTop20">
                        <div>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Start Date"
                                value={startDate}
                                onChange={date => setStartDate(date)}
                                autoOk={true}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                        <div className="marginLeft30">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="End Date"
                                value={endDate}
                                onChange={date => {
                                    date.setHours(23);
                                    setEndDate(date)
                                }}
                                autoOk={true}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                    </div>
                    <div className="marginTop30 flex">
                        {loading ?
                            <CircularProgress size={35} />
                            :
                            <Button variant="contained" color="secondary" onClick={onSearch}>Search</Button>
                        }
                    </div>
                </CardContent>
            </Card>
            <div className="marginTop30">
                {records && records.length ?
                    <div>
                        <div className="flex justify-end">
                            {exporting ?
                                <CircularProgress size={35}/>
                                :
                                <Button variant="contained" color="secondary" onClick={exportRecords}>Export</Button>
                            }
                        </div>
                        <Paper className="marginTop30">
                            <div className="overflow-x-scroll">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {FIELDS.map((field, index) => (
                                                !field.hidden ?
                                                    <TableCell key={`header-${index}`} style={{textTransform: 'capitalize', cursor: 'pointer', fontWeight: 'bold'}}>
                                                        {field.label}
                                                    </TableCell>
                                                    :
                                                    null
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {records.map((record, index) => (
                                            <TableRow key={`row-${index}`}>
                                                {FIELDS.map((field, fieldIndex) => (
                                                    !field.hidden ?
                                                        <TableCell key={`field-${index}-${fieldIndex}`}>
                                                            {field.key ? record[field.key] : field.format(record)}
                                                        </TableCell>
                                                        :
                                                        null
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </div>
                    :
                    "No records to display."
                }
            </div>
        </div>
    )
};

export default Donations;