export const loadSupportRecords = (startDate, endDate, callback) => (
    (dispatch, getState, { api }) => {
        return api.getSupportRecordsByDate(startDate, endDate)
            .then(results => callback(results));
    }
);

export const loadDonations = (startDate, endDate, callback) => (
    (dispatch, getState, { api }) => {
        return api.getDonationsByDate(startDate, endDate)
            .then(results => callback(results));
    }
);

export const exportDonations = (records, setExporting, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.exportDonations(records)
            .then(result => {
                setExporting(false);
                window.open(result.data);
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error("error exporting donations", err);
                setExporting(false);
            })
    }
);

export const exportSupportRecords = (records, setExporting, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.exportSupportRecords(records)
            .then(result => {
                setExporting(false);
                window.open(result.data);
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error("error exporting support records", err);
                setExporting(false);
            })
    }
);