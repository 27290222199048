import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import CallbacksSearch from '../../callbacks';
import TCEDialog from "../../_common/dialog";

const CallbacksSearchDialog = ({ onSearchSaved, open, setOpen }) => {
    const [callbacks, setCallbacks] = useState([]);
    const [cancelled, setCancelled] = useState(false);

    useEffect(() => {
        if (!open && !cancelled && callbacks) {
            onSearchSaved(callbacks)
        }
    }, [open, cancelled, callbacks, onSearchSaved])

    return (
        <TCEDialog
            open={open}
            onClose={() => setOpen(false)}
            title="Callbacks Search"
            maxWidth="lg"
            showTitle={true}
            actions={[
                { label: 'Cancel', onClick: () => {
                    setCancelled(true);
                    setOpen(false);
                }},
                { label: 'Show on Map', onClick: () => {
                    setCancelled(false);
                    setOpen(false);
                }},
            ]}>
                <CallbacksSearch onSearchCompleted={setCallbacks}/>
        </TCEDialog>
    )
};

CallbacksSearchDialog.propTypes = {
    onSearchSaved: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default CallbacksSearchDialog