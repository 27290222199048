import React from 'react';
import TextField from "@material-ui/core/TextField";
import {Formik, Form} from "formik";
import {useDispatch} from "react-redux";
import {SCHEMA as canvasserSchema} from '@devsontap/tce-api/core/models/canvasser';
import {useSnackbar} from "notistack";

import OfficeSelect from "../../../_common/officeSelect";
import CustomFormikField from "../../../_common/formik/customField";
import PhoneNumberTextField from "../../../_common/textfields/phoneNumber";
import {checkPhoneNumberAndSaveCanvasser} from "../../../../redux/canvassers/actions";

import './index.css';

const CanvasserDetailsForm = ({ canvasser, goBack, submitRef }) => {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Formik
            initialValues={{
                createdAt: new Date(),
                canvasserId: '',
                firstName: '',
                lastName: '',
                office: '',
                phoneNumber: '',
                disabled: false,
                ...canvasser
            }}
            onSubmit={values => {
                dispatch(checkPhoneNumberAndSaveCanvasser(values, goBack, enqueueSnackbar));
            }}
            validationSchema={canvasserSchema}>
            {({values}) => {
                console.log("values", values);
                return (
                    <Form className="canvasser-details-form">
                        <div className="canvasser-details-form-header">
                            {canvasser.id ? "Edit" : "Add New"} Canvasser
                        </div>
                        <div className="marginBottom15">
                            <CustomFormikField
                                CustomComponent={TextField}
                                name="firstName"
                                label="First Name"
                                placeholder="John"
                                fullWidth={true}
                                inputProps={{maxLength: 200}}
                            />
                        </div>
                        <div className="marginBottom15">
                            <CustomFormikField
                                CustomComponent={TextField}
                                name="lastName"
                                label="Last Name"
                                placeholder="Smith"
                                fullWidth={true}
                                inputProps={{maxLength: 200}}
                            />
                        </div>
                        <div className="marginBottom15">
                            <CustomFormikField
                                CustomComponent={PhoneNumberTextField}
                                name="phoneNumber"
                                label="Phone #"
                                placeholder="(512) 555-5555"
                                fullWidth={true}
                            />
                        </div>
                        <div className="marginBottom15">
                            <CustomFormikField
                                CustomComponent={TextField}
                                name="canvasserId"
                                label="Canvasser ID"
                                placeholder="tce-18238"
                                fullWidth={true}
                                inputProps={{maxLength: 200}}
                            />
                        </div>
                        <div className="marginBottom15 canvasser-details-office-select">
                            <CustomFormikField
                                CustomComponent={OfficeSelect}
                                name="office"
                                hideError={true}  />
                        </div>
                        <input type="submit" value="Submit" ref={submitRef} className="canvasser-details-form-submit-btn" />
                    </Form>
                )
            }}
        </Formik>
    )
};

export default CanvasserDetailsForm;