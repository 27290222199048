import {useShallowEqualSelector} from "../index";

export const useSupportersSelector = () => {
    return useShallowEqualSelector(({ supporters: { supportersLoading, supporters, lastImportDate, flaggedSupportersLoading, flaggedSupporters } }) => {
        return {
            supportersLoading,
            supporters,
            lastImportDate,
            flaggedSupporters,
            flaggedSupportersLoading
        };
    });
};