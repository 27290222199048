import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";

import './index.css';

const LargeAppBar = ({ loading, title, onBackClick, onSaveClick, onCancelClick }) => {
    return (
        <AppBar position="fixed" color="primary">
            <Toolbar className="large-app-bar-toolbar">
                <div className="flex flex1 large-app-bar-toolbar-inner">
                    <div>
                        <IconButton color="inherit" onClick={onBackClick} aria-label="Back">
                            <ArrowBack />
                        </IconButton>
                    </div>
                    <div className="flex align-items-center flex1 text-align-left appbar-title">
                        {title}
                    </div>
                    {loading ?
                        <div className="marginRight45">
                            <CircularProgress size={40} color="inherit" />
                        </div>
                        :
                        <div className="flex">
                            <div className="large-app-bar-save-container">
                                <Button variant="contained" color="secondary" onClick={onSaveClick}>
                                    Save
                                </Button>
                            </div>
                            <div>
                                <Button variant="text" onClick={onCancelClick}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </Toolbar>
        </AppBar>
    )
};

export default LargeAppBar;