import React, { useEffect, useState, useMemo } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import AddIcon from "@material-ui/icons/Add";

import CampaignItem from "./item";
import CampaignDetails from "./details";
import Loading from "../_common/loading";
import FullscreenDialog from "../_common/dialog/fullScreen";
import YellowFab from "../_common/yellowFab";
import {loadCampaigns} from "../../redux/campaigns";
import {campaignsSelector, campaignsLoadingSelector, campaignLastToggleSelector} from "../../redux/campaigns/selectors";
import TextField from "@material-ui/core/TextField";
import searchIco from "../../img/icn-search.svg";
import useFilters from "../../hooks/useFilters";
import OrganizationSelect from "../_common/organizationSelect";
import useFilterCampaigns from "../../hooks/useFilterCampaigns";

import './index.css';

const Campaigns = () => {
    const [ search, setSearch ] = useState('');
    const [ showDisabled, setShowDisabled ] = useState(false);
    const [orgId, setOrgId] = useState('');
    const campaignsLoading = useSelector(campaignsLoadingSelector);
    const allCampaigns = useSelector(campaignsSelector);
    const lastToggle = useSelector(campaignLastToggleSelector);
    const campaigns = useFilterCampaigns(allCampaigns, orgId);
    const dispatch = useDispatch();
    const { campaignId } = useParams();

    const dependencies = [lastToggle, campaigns];

    const enabledCampaigns = useMemo(() => campaigns ? campaigns.filter(campaign => !campaign.disabled) : null, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
    const disabledCampaigns = useMemo(() => campaigns ? campaigns.filter(campaign => campaign.disabled) : null, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

    const filteredEnabled = useFilters(enabledCampaigns, [
        campaign =>  _searchFilter(campaign, search)
    ], [search, enabledCampaigns]);

    const filteredDisabled = useFilters(disabledCampaigns, [
        campaign =>  _searchFilter(campaign, search)
    ], [search, disabledCampaigns]);

    useEffect(() => {
        dispatch(loadCampaigns());
    }, [dispatch]);

    return (
        <div className="campaigns-container">
            {campaignsLoading || !enabledCampaigns || !disabledCampaigns ?
                <Loading size={45} />
                :
                <div>
                    <Link to={`/campaigns/add`} className="campaigns-add-fab">
                        <YellowFab aria-label="Add" onClick={() => {}}>
                            <AddIcon htmlColor="#ffffff" />
                        </YellowFab>
                    </Link>
                    <div className="marginBottom30 flex">
                        <div style={{ width: 400 }}>
                            <OrganizationSelect value={orgId} onChange={e => setOrgId(e.target.value)} showEmpty />
                        </div>
                    </div>
                    <div className="marginBottom30 flex align-end">
                        <div className="flex">
                            <div className="flex1 alignLeft canvassers-search-card">
                                <TextField
                                    name="search"
                                    label="Search"
                                    type="search"
                                    placeholder="Search Campaigns"
                                    hint="Search by Campaign Name"
                                    value={search}
                                    onChange={event => setSearch(event.target.value.toLowerCase())}
                                    InputProps={{startAdornment: <img src={searchIco} alt="search" className="marginRight10" />}}
                                    fullWidth />
                            </div>
                        </div>
                        <div className="flex align-items-center marginLeft30">
                            <Checkbox checked={showDisabled} onChange={event => setShowDisabled(event.target.checked)} />
                            Show Deactivated Campaigns
                        </div>
                    </div>
                    {showDisabled &&
                    <>
                        <div className="page-header">
                            Deactivated Campaigns
                        </div>
                        <div className="marginBottom30">
                            {filteredDisabled && filteredDisabled.length ?
                                filteredDisabled.map(campaign => (
                                    <CampaignItem campaignId={campaign.id} key={`campaign-item-${campaign.id}`} />
                                ))
                                :
                                <div className="marginTop10">
                                    No disabled campaigns.
                                </div>
                            }
                        </div>
                    </>
                    }
                    <div className="page-header">
                        Active Campaigns
                    </div>
                    <div className="marginBottom30">
                        {filteredEnabled && filteredEnabled.length ?
                            filteredEnabled.map(campaign => (
                                <CampaignItem campaignId={campaign.id} key={`campaign-item-${campaign.id}`} />
                            ))
                            :
                            <div className="marginTop10">
                                No campaigns.
                            </div>
                        }
                    </div>
                </div>
            }
            <FullscreenDialog onClose={() => {}} open={Boolean(campaignId)}>
                <CampaignDetails campaignId={campaignId} />
            </FullscreenDialog>
        </div>
    )
};

function _searchFilter(campaign, search) {
    return campaign.campaignName.toLowerCase().indexOf(search) > -1
}

export default Campaigns;