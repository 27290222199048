import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import './index.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Takes over the entire screen as a modal dialog
 * @param open      True if dialog is visible
 * @param onClose   Function for dismissing the dialog
 * @param children  Child components to render
 */
const FullscreenDialog = ({open, onClose, children}) => (
    <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}>
        {children}
    </Dialog>
);

FullscreenDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.any,
};

export default FullscreenDialog;