import {
    CANVASSERS_LOADING,
    CANVASSERS_SUCCESS,
    CANVASSERS_ERROR,
    CANVASSER_LOADING,
    CANVASSER_SAVING,
    CANVASSER_SUCCESS,
    CANVASSER_ERROR,
    ENABLE_CANVASSER,
    DISABLE_CANVASSER
} from './types';

export const loadCanvassers = () => (
    (dispatch, getState, { api }) => {
        dispatch({ type: CANVASSERS_LOADING });
        return api.getCanvassers()
            .then(canvassers => {
                return dispatch({ type: CANVASSERS_SUCCESS, payload: canvassers });
            })
            .catch(err => {
                console.error(err);
                return dispatch({ type: CANVASSERS_ERROR });
            })
    }
);

export const enableCanvasser = (canvasser) => (
    (dispatch, getState, { api }) => {
        return dispatch(saveCanvasser({ ...canvasser, disabled: false }))
            .then(() => {
                return dispatch({ type: ENABLE_CANVASSER, payload: canvasser.id });
            });
    }
);

export const disableCanvasser = (canvasser) => (
    (dispatch, getState, { api }) => {
        return dispatch(saveCanvasser({ ...canvasser, disabled: true }))
            .then(() => {
                return dispatch({ type: DISABLE_CANVASSER, payload: canvasser.id });
            });
    }
);

export const loadCanvasser = (canvasserId) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: CANVASSER_LOADING });
        return api.getCanvasser(canvasserId)
            .then(canvasser => {
                return dispatch({ type: CANVASSER_SUCCESS, payload: canvasser });
            })
            .catch(err => {
                console.error(err);
                return dispatch({ type: CANVASSER_ERROR });
            })
    }
);

export const checkPhoneNumberAndSaveCanvasser = (canvasser, goBack, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        dispatch({ type: CANVASSER_SAVING });
        const dbCanvasser = await api.getCanvasserByPhoneNumber(canvasser.phoneNumber);
        if (dbCanvasser && dbCanvasser.id !== canvasser.id) {
            enqueueSnackbar("A Canvasser exists with the Phone Number entered. Please try again.", {variant: 'error'});
            return dispatch({ type: CANVASSER_ERROR });
        }
        return dispatch(saveCanvasser(canvasser, goBack));
    }
)

export const saveCanvasser = (canvasser, goBack) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: CANVASSER_SAVING });
        return api.saveCanvasser(canvasser)
            .then(canvasserId => {
                if (goBack) {
                    goBack();
                }
                return dispatch({ type: CANVASSER_SUCCESS, payload: {id: canvasserId, ...canvasser} });
            })
            .catch(err => {
                console.error(err);
                return dispatch({ type: CANVASSER_ERROR });
            })
    }
);

export const loadOffices = (callback) => (
    (dispatch, getState, { api }) => {
        return api.getOffices()
            .then(offices => callback(offices));
    }
);