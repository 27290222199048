import {useShallowEqualSelector} from "../index";

export const useCanvassersSelector = () => {
    return useShallowEqualSelector(({ canvassers: { canvassersLoading, canvasserIds, canvasserMap, lastToggle } }) => {
        return { canvassersLoading, canvasserIds, lastToggle, canvassers: canvasserIds ? canvasserIds.map(id => canvasserMap[id]) : null };
    });
};

export const useCanvasserDetailsSelector = (canvasserId) => {
    return useShallowEqualSelector(({ canvassers: { canvasserMap, canvasserLoading, canvasserSaving } }) => {
        let canvasser = {};
        if (canvasserId !== "add") {
            canvasser = canvasserMap[canvasserId];
        }
        return { canvasser, canvasserLoading, canvasserSaving };
    });
};

