import { createSlice } from '@reduxjs/toolkit';
import { getLocal, saveLocal } from "@devsontap/dot-react-common/core/utils/localStorage";

const KEY_CALLBACKS = "callbacks";

const INITIAL_STATE = {
    loading: false,
    callbackMap: getLocal(KEY_CALLBACKS, true) || {}
};

const callbacksSlice = createSlice({
    name: 'callbacks',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        callbacksSuccess(state, action) {
            state.loading = false;
            state.callbackMap = action.payload.reduce((ret, callback) => ({...ret, [callback.id]: callback }), state.callbackMap);
            saveLocal(KEY_CALLBACKS, state.callbackMap, true);
        },
    }
});

export const { loading, callbacksSuccess } = callbacksSlice.actions;

export default callbacksSlice.reducer;

// CUSTOM THUNK ACTIONS

export const loadCallbacks = (startDate, endDate, enqueueSnackbar, onSuccess) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.callbacks.getByDate(startDate, endDate)
            .then(callbacks => {
                console.log("Callbacks", callbacks);
                if (onSuccess) {
                    onSuccess(callbacks)
                }
                return dispatch(callbacksSuccess(callbacks));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            })
    }
);

export const deleteCallbacks = (callbackIds, setLoading, enqueueSnackbar, onSuccess) => (
    (dispatch, getState, { api }) => {
        return Promise.all(callbackIds.map(callbackId => api.callbacks.delete(callbackId)))
            .then(() => {
                setLoading(false);
                enqueueSnackbar("Callbacks Deleted", { variant: "success" });
                onSuccess([]);
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                setLoading(false);
            });
    }
);