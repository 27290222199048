import FormattedTextField from './formatted';

/**
 * This component formats input as a PhoneNumber
 */
class PhoneNumberTextField extends FormattedTextField {

    constructor(props) {
        super(props);
        this.state = {
            formattedValue: this.formatValue(this.props.value).formatted
        }
    }

    formatValue = (value) => {
        let numbers = "";
        if (value) {
            numbers = value.replace(/[^0-9]+/g, "");
        }

        let formatted = numbers;
        if (numbers.length > 6) {
            formatted = `(${numbers.substring(0, 3)}) ${numbers.substring(3, 6)}-${numbers.substring(6, 10)}`;
        } else if (numbers.length > 3) {
            formatted = `(${numbers.substring(0, 3)}) ${numbers.substring(3, 6)}`;
        } else if (numbers.length > 0) {
            formatted = `(${numbers.substring(0, 3)}`;
        }

        return {
            formatted,
            raw: numbers.substring(0, 10)
        };
    }
}

export default PhoneNumberTextField;