import React from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

const SortableItem = SortableElement(({ children, zIndex }) => (
    <div style={{ zIndex }}>
        {children}
    </div>
));

const SortableList = SortableContainer(({ children, zIndex }) => {
    if (children && children.length) {
        return (
            <div className="flex">
                {children.map((child, index) => <SortableItem index={index} zIndex={zIndex} key={`sortable-item-${index}`}>{child}</SortableItem>)}
            </div>
        );
    }
    return null;
});

export default SortableList;
