export const CANVASSERS_LOADING = "CANVASSERS_LOADING";
export const CANVASSERS_SUCCESS = "CANVASSERS_SUCCESS";
export const CANVASSERS_ERROR = "CANVASSERS_ERROR";

export const CANVASSER_LOADING = "CANVASSER_LOADING";
export const CANVASSER_SAVING = "CANVASSER_SAVING";
export const CANVASSER_SUCCESS = "CANVASSER_SUCCESS";
export const CANVASSER_ERROR = "CANVASSER_ERROR";

export const ENABLE_CANVASSER = "ENABLE_CANVASSER";
export const DISABLE_CANVASSER = "DISABLE_CANVASSER";