import React, {useEffect, useState} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch} from "react-redux";

import {loadOffices} from "../../../redux/canvassers/actions";

import './index.css';

const OfficeSelect = ({value, onChange, error, progressSize}) => {
    const [loading, setLoading] = useState(true);
    const [offices, setOffices] = useState(null);
    const dispatch = useDispatch();

    console.log("value", value);

    useEffect(() => {
        dispatch(loadOffices(offices => {
            setOffices(offices);
            setLoading(false);
        }))
    }, [dispatch]);

    return loading ?
            <CircularProgress size={progressSize}/>
            :
            <FormControl fullWidth error={error}>
                <InputLabel id="officeLabel">Office</InputLabel>
                <Select
                    name="office"
                    value={value}
                    labelId="officeLabel"
                    onChange={onChange}
                    displayEmpty>
                    {offices.map((office, index) => (
                        <MenuItem value={office} key={`office-${office}`}>
                            {office}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
};

OfficeSelect.defaultProps = {
    progressSize: 45
};

export default OfficeSelect;