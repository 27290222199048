import { createSelector } from '@reduxjs/toolkit';

import { useShallowEqualSelector } from "../index";

const turfMapSelector = state => state.turfs.turfMap;
export const turfsLoadingSelector = state => state.turfs.loading;
export const turfSavingSelector = state => state.turfs.turfSaving;
export const lastUpdateSelector = state => state.turfs.lastUpdate;

export const turfsSelector = createSelector(
    turfMapSelector,
    (turfMap) => {
        return Object.values(turfMap) || [];
    }
);

export const turfsInfoSelector = createSelector(
    turfsSelector, turfsLoadingSelector, turfSavingSelector, lastUpdateSelector,
    (turfs, turfsLoading, turfSaving, lastUpdate) => {
        return {
            turfsLoading,
            turfSaving,
            turfs,
            turfsIdString: turfs.map(turf => turf.id).join(","),
            lastUpdate
        }
    }
);

export const useTurfDetailsSelector = (turfId) => {
    return useShallowEqualSelector(({ turfs: { turfMap } }) => {
        return turfMap[turfId];
    });
};