import {
    CANVASSERS_LOADING,
    CANVASSERS_SUCCESS,
    CANVASSERS_ERROR,
    CANVASSER_LOADING,
    CANVASSER_SUCCESS,
    CANVASSER_SAVING,
    CANVASSER_ERROR,
    ENABLE_CANVASSER,
    DISABLE_CANVASSER
} from "./types";

const INITIAL_STATE = {
    canvassersLoading: false,
    canvasserLoading: false,
    canvasserSaving: false,
    canvasserMap: {},
    canvasserIds: null,
    lastToggle: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CANVASSERS_LOADING:
            return {
                ...state,
                canvassersLoading: true
            };
        case CANVASSERS_SUCCESS:
            return {
                ...state,
                canvassersLoading: false,
                canvasserMap: action.payload.reduce((ret, canvasser) => {
                    ret[canvasser.id] = canvasser;
                    return ret;
                }, state.canvasserMap),
                canvasserIds: action.payload.map(canvasser => canvasser.id)
            };
        case CANVASSERS_ERROR:
            return {
                ...state,
                canvassersLoading: false
            };
        case CANVASSER_LOADING:
            return {
                ...state,
                canvasserLoading: true
            };
        case CANVASSER_SAVING:
            return {
                ...state,
                canvasserSaving: true
            };
        case CANVASSER_SUCCESS:
            return {
                ...state,
                canvasserLoading: false,
                canvasserSaving: false,
                canvasserMap: {
                    ...state.canvasserMap,
                    [action.payload.id]: action.payload
                },
                canvasserIds: _addCanvasserIdIfNeeded(action.payload, state.canvasserIds)
            };
        case CANVASSER_ERROR:
            return {
                ...state,
                canvasserLoading: false,
                canvasserSaving: false
            };
        case ENABLE_CANVASSER:
            return {
                ...state,
                canvasserMap: {
                    ...state.canvasserMap,
                    [action.payload]: {
                        ...state.canvasserMap[action.payload],
                        disabled: false
                    }
                },
                lastToggle: new Date().getTime()
            };
        case DISABLE_CANVASSER:
            return {
                ...state,
                canvasserMap: {
                    ...state.canvasserMap,
                    [action.payload]: {
                        ...state.canvasserMap[action.payload],
                        disabled: true
                    }
                },
                lastToggle: new Date().getTime()
            };
        default:
            return state;
    }
}

function _addCanvasserIdIfNeeded(canvasser, canvasserIds) {
    if (canvasserIds && !canvasserIds.includes(canvasser.id)) {
        return [canvasser.id, ...canvasserIds];
    }
    return canvasserIds;
}