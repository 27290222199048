/*global google*/
import {useState, useEffect} from 'react';

function useMarkerManager(map, pins, icon = undefined, fitBounds = false) {
    const [markers, setMarkers] = useState(null);

    useEffect(() => {
        if (map && pins && pins.length) {
            markers && markers.forEach(marker => marker.setMap(null));
            const bounds = map.getBounds();
            const newMarkers = [];
            pins.forEach(pin => {
                // NOTE: could consider adding info windows to these markers
                // so canvassers/admins can get information by clicking the marker
                newMarkers.push(new google.maps.Marker({
                    position: {lat: pin.lat, lng: pin.lng},
                    map,
                    ...(icon && { icon }),
                }));
                if (fitBounds) {
                    bounds.extend({lat: pin.lat, lng: pin.lng});
                }
            });
            if (fitBounds) {
                map.fitBounds(bounds);
            }
            setMarkers(newMarkers);
        }
    }, [map, pins ? pins.length : 0]);  // eslint-disable-line react-hooks/exhaustive-deps

    return markers;
}

export default useMarkerManager;