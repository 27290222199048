import React, {useState} from 'react';
import Paper from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Switch from "@material-ui/core/Switch";
import {Formik, Form} from "formik";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import arrayMove from 'array-move';
import {SCHEMA as campaignSchema} from '@devsontap/tce-api/core/models/campaign'

import Survey from "../survey";
import DropZone from "../../../_common/dropZone";
import SortableList from "../../../_common/sortableList";
import CustomFormikField from "../../../_common/formik/customField";
import {saveCampaign, uploadSupportStatement, uploadInfographic} from "../../../../redux/campaigns";

import remove_btn from '../../../../img/btn-remove-photo.svg';

import './index.css';
import OrganizationSelect from "../../../_common/organizationSelect";

const CampaignDetailsForm = ({ campaign, submitRef, goBack }) => {
    const [supportStatementUploading, setSupportStatementUploading] = useState(false);
    const [infographicUploading, setInfographicUploading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const goToNewRecord = (newId) => history.push(`/campaigns/${newId}`);

    const onDeleteClick = (index, infographics, setFieldValue) => {
        const newInfographics = [...infographics];
        newInfographics.splice(index, 1);
        setFieldValue('infographics', newInfographics, false);
    };

    const shouldCancelStart = (event) => Boolean(event.target.className.match(/MuiIconButton-label|MuiIconButton-root/));

    const onSortEnd = (info, infographics, setFieldValue) => setFieldValue("infographics", arrayMove(infographics, info.oldIndex, info.newIndex), false);

    return (
        <Formik
            initialValues={{
                createdAt: new Date(),
                campaignName: '',
                description: '',
                infographics: [],
                supportStatementUrl: '',
                survey: [],
                emailTemplates: {
                    noContribution: '',
                    contribution: '',
                    fundContribution: '',
                    recurringContribution: '',
                    fundRecurringContribution: ''
                },
                hideContributionScreen: false,
                orgId: '',
                ...campaign
            }}
            onSubmit={values => {
                dispatch(saveCampaign(values, goToNewRecord, goBack));
            }}
            validationSchema={campaignSchema}>
            {({values, setFieldValue}) => {
                return (
                    <Form className="campaign-details-form">
                        <div className="campaign-details-form-header">
                            Campaign Info
                        </div>
                        <div className="marginBottom15">
                            <CustomFormikField
                                CustomComponent={OrganizationSelect}
                                name="orgId"
                                label="Organization"
                                fullWidth={true}
                            />
                        </div>
                        <div className="marginBottom15">
                            <CustomFormikField
                                CustomComponent={TextField}
                                name="campaignName"
                                label="Campaign Name"
                                placeholder="Organizing To Win"
                                fullWidth={true}
                                inputProps={{maxLength: 500}}
                            />
                        </div>
                        <div className="marginBottom30">
                            <CustomFormikField
                                CustomComponent={TextField}
                                name="description"
                                label="Description"
                                placeholder="Together we can make a bigger change..."
                                fullWidth={true}
                                inputProps={{maxLength: 1000}}
                            />
                        </div>
                        {!campaign.id &&
                            <div className="marginTop60">
                                <Button variant="contained" color="secondary" type="submit" fullWidth>
                                    Save to Continue
                                </Button>
                            </div>
                        }
                        {campaign.id &&
                            <>
                                <div className="campaign-details-form-header marginTop60">
                                    Email Templates
                                </div>
                                <div className="flex">
                                    <div className="flex1">
                                        <CustomFormikField
                                            CustomComponent={TextField}
                                            name="emailTemplates.noContribution"
                                            label="New Supporter"
                                            placeholder="3k4k2-kkskk24-skdjs223494"
                                            inputProps={{maxLength: 100, autoComplete: "off"}}
                                            fullWidth />
                                    </div>
                                </div>
                                <div className="flex marginTop10">
                                    <div className="flex1">
                                        <CustomFormikField
                                            CustomComponent={TextField}
                                            name="emailTemplates.contribution"
                                            label="New Supporter With Contribution"
                                            placeholder="3k4k2-kkskk24-skdjs223494"
                                            inputProps={{maxLength: 100, autoComplete: "off"}}
                                            fullWidth />
                                    </div>
                                </div>
                                <div className="flex marginTop10">
                                    <div className="flex1">
                                        <CustomFormikField
                                            CustomComponent={TextField}
                                            name="emailTemplates.recurringContribution"
                                            label="Recurring Supporter With Contribution"
                                            placeholder="3k4k2-kkskk24-skdjs223494"
                                            inputProps={{maxLength: 100, autoComplete: "off"}}
                                            fullWidth />
                                    </div>
                                </div>
                                <div className="campaign-details-form-header marginTop60">
                                    Hide Contribution Screen
                                </div>
                                <div>
                                    <Switch
                                        checked={values.hideContributionScreen}
                                        onChange={event => setFieldValue('hideContributionScreen', event.target.checked, false)}
                                        color="secondary" />
                                    Do not show Contribution Screen
                                </div>
                                <div className="campaign-details-form-header marginTop60">
                                    Support Statement
                                </div>
                                <div className="campaign-details-form-dropzone">
                                    <DropZone
                                        accept=".jpg,.jpeg,.png"
                                        loading={supportStatementUploading}
                                        onDrop={files => {
                                            console.log("files", files);
                                            setSupportStatementUploading(true);
                                            dispatch(uploadSupportStatement(files[0], campaign.id, url => {
                                                setFieldValue("supportStatementUrl", url, false);
                                                setSupportStatementUploading(false);
                                            }));
                                        }} />
                                </div>
                                {values.supportStatementUrl &&
                                <div className="flex marginTop15">
                                    <Paper square elevation={2}>
                                        <img src={values.supportStatementUrl} alt="Support Statement" className="campaign-details-support-statement-img" />
                                    </Paper>
                                </div>
                                }
                                <div className="campaign-details-form-header marginTop60">
                                    Infographics
                                </div>
                                <div className="campaign-details-form-dropzone">
                                    <DropZone
                                        accept=".jpg,.jpeg,.png"
                                        loading={infographicUploading}
                                        onDrop={files => {
                                            setInfographicUploading(true);
                                            dispatch(uploadInfographic(files[0], campaign.id, url => {
                                                setFieldValue("infographics", [...values.infographics, url], false);
                                                setInfographicUploading(false);
                                            }));
                                        }} />
                                </div>
                                <div className="marginTop20">
                                    {values.infographics && values.infographics.length ?
                                        <SortableList axis="x" zIndex={1300} onSortEnd={info => onSortEnd(info, values.infographics, setFieldValue)} shouldCancelStart={shouldCancelStart}>
                                            {values.infographics.map((url, index) => (
                                                <div key={url} className="marginRight20 relative">
                                                    <Paper square elevation={2}>
                                                        <img src={url} alt="Infographic" className="campaign-details-infographic-img" />
                                                    </Paper>
                                                    <div className="campaign-details-form-infographic-delete-container">
                                                        <IconButton id={`delete-button${index}`} onClick={() => onDeleteClick(index, values.infographics, setFieldValue)}>
                                                            <img id={`remove-img-${index}`} src={remove_btn} alt="remove button" style={{ pointerEvents: 'none' }} />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            ))}
                                        </SortableList>
                                        :
                                        null
                                    }
                                </div>
                                <div className="campaign-details-form-header marginTop60">
                                    Survey Questions
                                </div>
                                <Survey
                                    survey={values.survey}
                                    setFieldValue={setFieldValue} />
                            </>
                        }
                        <input type="submit" value="Submit" ref={submitRef} className="campaign-details-form-submit-btn" />
                    </Form>
                )
            }}
        </Formik>
    )
};

export default CampaignDetailsForm;