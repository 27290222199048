import React, {useState} from 'react';
import Card from "@material-ui/core/Card";

import LoginForm from "./loginForm";
import ForgotPasswordForm from "./forgotPasswordForm";

import logo from '../../img/logo@2x.png';

import './index.css';

const Login = () => {
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    return (
        <div className="login-container column center">
            <div className="login-background" />
            <Card className="login-card">
                <div className="alignLeft">
                    <img src={logo} className="login-logo" alt="Texas Campaign for the Environment Logo" />
                </div>
                {!showForgotPassword ?
                    <LoginForm showForgotPassword={() => setShowForgotPassword(true)} />
                    :
                    <ForgotPasswordForm hideForgotPassword={() => setShowForgotPassword(false)} />
                }
            </Card>
        </div>
    )
};

export default Login;