import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Delete from '@material-ui/icons/Delete';
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {SurveyQuestionTypes} from '@devsontap/tce-api/core/models/enums/index';

import CustomFormikField from "../../../../_common/formik/customField";

import upCarat from '../../../../../img/ic_keyboard_arrow_up_black_36px.svg';
import downCarat from '../../../../../img/ic_keyboard_arrow_down_black_36px.svg';

import './index.css';

const SurveyItem = ({ value: question, deleteQuestion, sortQuestions, index, setFieldValue }) => {
    const {type, choices, required} = question;

    const addOption = () => {
        setFieldValue(`survey[${index}].choices[${choices.length}]`, "", false);
    };

    const deleteOption = (optionIndex) => {
        const update = [...choices];
        update.splice(optionIndex, 1);
        setFieldValue(`survey[${index}].choices`, update, false);
    };

    const onTypeChange = (event) => {
        setFieldValue(`survey[${index}].type`, event.target.value, false);
        if (event.target.value === SurveyQuestionTypes[0].value) {
            setFieldValue(`survey[${index}].choices`, choices.filter(choice => choice.length))
        } else if (!choices.length) {
            setFieldValue(`survey[${index}].choices`, [''])
        }
    };

    return (
        <div>
            <div className="flex">
                <div className="flex1">
                    <div className="flex">
                        <div className="survey-question-text flex1">
                            <CustomFormikField
                                CustomComponent={TextField}
                                name={`survey[${index}].questionText`}
                                label="Question Text"
                                placeholder="Will you write a letter to your incumbents?"
                                fullWidth={true}/>
                        </div>
                        <div className="marginLeft30">
                            <div>
                                <FormControl>
                                    <InputLabel id="type-label">Type</InputLabel>
                                    <CustomFormikField
                                        CustomComponent={Select}
                                        name={`survey[${index}].type`}
                                        labelId="type-label"
                                        onChange={onTypeChange}>
                                        {SurveyQuestionTypes.map((surveyType, index) => {
                                            return (
                                                <MenuItem value={surveyType.value}
                                                          key={"survey-question-type-" + index}>
                                                    {surveyType.value}
                                                </MenuItem>
                                            )
                                        })}
                                    </CustomFormikField>
                                </FormControl>
                            </div>
                        </div>
                        <div className="marginLeft30">
                            <IconButton onClick={() => deleteQuestion(index)}><Delete/></IconButton>
                        </div>
                    </div>
                    <div style={{margin: "10px 0 10px -15px"}}>
                        <Checkbox
                            name={`survey[${index}].required`}
                            checked={required}
                            onChange={event => setFieldValue(event.target.name, event.target.checked, false)} /> Required
                    </div>
                    <div>
                        {type !== SurveyQuestionTypes[0].value ?
                            <div>
                                <div className="roboto survey-question-options-header marginTop10">
                                    Options
                                </div>
                                <div>
                                    {choices.map((option, optionIndex) => {
                                        return (
                                            <div className="flex marginTop15 marginBottom15"
                                                 key={`survey-question-option-${optionIndex}`}>
                                                <div className="marginTop10">
                                                    {type === SurveyQuestionTypes[2].value ?
                                                        <Radio/>
                                                        :
                                                        <Checkbox/>
                                                    }
                                                </div>
                                                <div className="survey-question-option-text">
                                                    <CustomFormikField
                                                        CustomComponent={TextField}
                                                        name={`survey[${index}].choices[${optionIndex}]`}
                                                        label="Option Text"
                                                        placeholder="Enter an Answer"
                                                        defaultValue=""
                                                        fullWidth={true}/>
                                                </div>
                                                <div className="marginTop10">
                                                    {optionIndex > 0 ?
                                                        <IconButton onClick={() => deleteOption(optionIndex)}>
                                                            <Delete/>
                                                        </IconButton>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div>
                                    <Button variant="text" color="secondary" onClick={addOption}>
                                        + Add Option
                                    </Button>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="flex column">
                    <div>
                        <IconButton onClick={() => sortQuestions(index, index - 1)}>
                            <img src={upCarat} alt="up arrow"/>
                        </IconButton>
                    </div>
                    <div>
                        <IconButton onClick={() => sortQuestions(index, index + 1)}>
                            <img src={downCarat} alt="down arrow"/>
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className="marginTop30 marginBottom30">
                <Divider/>
            </div>
        </div>
    )
};

export default SurveyItem;