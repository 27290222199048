import {
    SUPPORTERS_LOADING,
    SUPPORTERS_SUCCESS,
    SUPPORTERS_ERROR,
    SUPPORTERS_IMPORTED,
    SUPPORTERS_IMPORT_ERROR,
    FLAGGED_SUPPORTERS_LOADING,
    FLAGGED_SUPPORTERS_SUCCESS,
    FLAGGED_SUPPORTERS_ERROR
} from "./types";

const INITIAL_STATE = {
    supportersLoading: false,
    supporters: null,
    lastImportDate: null,
    flaggedSupportersLoading: false,
    flaggedSupporters: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUPPORTERS_LOADING:
            return {
                ...state,
                supportersLoading: true
            };
        case SUPPORTERS_SUCCESS:
            return {
                ...state,
                supportersLoading: false,
                supporters: action.payload
            };
        case SUPPORTERS_ERROR:
            return {
                ...state,
                supportersLoading: false
            };
        case SUPPORTERS_IMPORTED:
            return {
                ...state,
                supportersLoading: false,
                lastImportDate: action.payload
            };
        case SUPPORTERS_IMPORT_ERROR:
            return {
                ...state,
                supportersLoading: false
            };
        case FLAGGED_SUPPORTERS_LOADING:
            return {
                ...state,
                flaggedSupportersLoading: true
            };
        case FLAGGED_SUPPORTERS_SUCCESS:
            return {
                ...state,
                flaggedSupportersLoading: false,
                flaggedSupporters: action.payload
            };
        case FLAGGED_SUPPORTERS_ERROR:
            return {
                ...state,
                flaggedSupportersLoading: false
            };
        default:
            return state;
    }
}