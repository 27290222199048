import React from 'react';
import TextField from '@material-ui/core/TextField';

/**
 * Triggers an 'onSubmit' function if passed to this component whenever the Enter key is pressed
 */
class AutoSubmitTextField extends React.Component {

    onKeyDown = (event) => {
        const {onSubmit} = this.props;
        if (event.keyCode === 13 && onSubmit) {
            onSubmit();
        }
    };

    render() {
        return (
            <TextField
                {...this.props}
                onKeyDown={this.onKeyDown} />
        )
    }

}

export default AutoSubmitTextField;