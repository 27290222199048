import React, {useRef} from 'react';
import Paper from '@material-ui/core/Card';
import {useHistory} from "react-router";

import CanvasserDetailsForm from './form';
import Loading from "../../_common/loading";
import LargeAppBar from "../../_common/largeAppBar";
import {useCanvasserDetailsSelector} from "../../../redux/canvassers/selectors";

import './index.css';

const CanvasserDetails = ({ canvasserId }) => {
    const { canvasser, canvasserSaving } = useCanvasserDetailsSelector(canvasserId);
    const history = useHistory();
    const submitRef = useRef(null);

    const goBack = () => history.push(document.location.pathname.replace("/" + canvasserId, ""));

    return (
        <div>
            {!canvasser ?
                <Loading size={45} />
                :
                <>
                    <LargeAppBar
                        loading={canvasserSaving}
                        title={canvasser.id ? "Edit Canvasser" : "Add Canvasser"}
                        onSaveClick={() => submitRef.current.click()}
                        onBackClick={goBack}
                        onCancelClick={goBack} />
                    <div className="flex justify-content-center">
                        <Paper className="canvasser-details-card" square elevation={4}>
                            <CanvasserDetailsForm canvasser={canvasser} submitRef={submitRef} saving={canvasserSaving} goBack={goBack} />
                        </Paper>
                    </div>
                </>
            }
        </div>
    )
};

export default CanvasserDetails;