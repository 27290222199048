import React, {useMemo, useRef, useState} from 'react';
import {Formik} from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Chip from '@material-ui/core/Chip';
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Pencil from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Search from '@material-ui/icons/Search';
import Warning from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {SCHEMA as TurfSchema} from '@devsontap/tce-api/core/models/turf';

import {saveTurf, deleteTurf} from "../../../redux/turfs";
import {turfsSelector, useTurfDetailsSelector} from "../../../redux/turfs/selectors";
import {useCanvassersSelector} from "../../../redux/canvassers/selectors";

import './index.css';

const CANVASSER_WARNING = "WARNING! This user is assigned to another Turf. Assigning them here will remove them from the other turf.";

const TurfDetails = ({ turfId, onClose, submitRef, editPolygon }) => {
    const [search, setSearch] = useState('');
    const [active, setActive] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const turf = useTurfDetailsSelector(turfId);
    const dispatch = useDispatch();
    const anchorRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();
    const { canvassers } = useCanvassersSelector();
    const allTurfs = useSelector(turfsSelector);

    // TODO: Turn this into a selector
    const enabledCanvassers = useMemo(() => canvassers ? canvassers.filter(canvasser => !canvasser.disabled) : null, [canvassers.length]); // eslint-disable-line react-hooks/exhaustive-deps
    const filteredCanvassers = useMemo(() => {
        const lowerSearch = search.toLowerCase();
        if (enabledCanvassers) {
            return enabledCanvassers.filter(canvasser => {
                return canvasser.firstName.toLowerCase().match(lowerSearch) ||
                    canvasser.lastName.toLowerCase().match(lowerSearch)
            });
        }
        return [];
    }, [enabledCanvassers.length, search]); // eslint-disable-line react-hooks/exhaustive-deps

    // TODO: Make this a selector
    const assignedCanvassers = useMemo(() => {
        const otherTurfs = allTurfs.filter(turf => turf.id !== turfId);
        return otherTurfs.reduce((ret, turf) => {
            const canvasserMap = turf.canvassers.reduce((map, canvasser) => ({
                ...map,
                [canvasser.id]: turf.id
            }), {});
            return {
                ...ret,
                ...canvasserMap
            };
        }, {})
    }, [allTurfs, turfId]);

    const onDelete = () => {
        dispatch(deleteTurf(turfId, enqueueSnackbar));
        onClose();
    };

    const onEdit = () => {
        editPolygon(turf);
        onClose();
    };

    return (
        <div className="turf-details-container">
            {!showConfirmDelete ?
                <>
                    {turf &&
                        <Formik
                            initialValues={{
                                name: '',
                                canvassers: [],
                                ...turf
                            }}
                            validationSchema={TurfSchema}
                            onSubmit={values => {
                                dispatch(saveTurf(values, enqueueSnackbar));
                                onClose();
                            }} >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue
                              }) => {
                                console.log("errors", turf);
                                return (
                                    <form onSubmit={handleSubmit} className="relative">
                                        <input type="submit" style={{display: 'none'}} ref={submitRef} />
                                        <div className="flex marginBottom20">
                                            <div className="marginRight30">
                                                <Button variant="text" color="primary" onClick={onEdit}>
                                                    <Pencil className="marginRight5"/>Edit Turf
                                                </Button>
                                            </div>
                                            <div>
                                                <Button variant="text" color="primary" onClick={() => setShowConfirmDelete(true)}>
                                                    <Delete className="marginRight5" /> Delete Turf
                                                </Button>
                                            </div>
                                        </div>
                                        <div>
                                            <TextField
                                                name="name"
                                                label="Turf Name"
                                                placeholder="SW Corner of 78701"
                                                value={values.name}
                                                onChange={handleChange}
                                                helperText={errors.name && touched.name ? errors.name : null}
                                                error={Boolean(touched.name && errors.name)}
                                                fullWidth/>
                                        </div>
                                        <div className="turf-details-section-header marginTop40">
                                            Canvassers
                                        </div>
                                        <div className="marginTop10 flex">
                                            <div className="flex align-end">

                                            </div>
                                            <TextField
                                                name="search"
                                                value={search}
                                                placeholder="Search for Canvasser"
                                                onChange={event => setSearch(event.target.value)}
                                                onBlur={() => { setTimeout(() => setActive(false), 200) }}
                                                onFocus={() => setActive(true)}
                                                ref={anchorRef}
                                                inputProps={{
                                                    autoComplete: "off"
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                fullWidth/>
                                        </div>
                                        <div className="turf-details-canvasser-suggestions">
                                            <Popper
                                                anchorEl={anchorRef.current}
                                                open={Boolean(search.length > 2 && active)}
                                                placement="bottom"
                                                style={{zIndex: "3000 !important"}}
                                                disablePortal={true}
                                                modifiers={{ flip: { enabled: false } }}>
                                                <Paper
                                                    square
                                                    className="autocomplete-paper"
                                                    style={{ width: anchorRef.current ? anchorRef.current.clientWidth : null }}>
                                                    {filteredCanvassers && filteredCanvassers.length ?
                                                        filteredCanvassers.map((canvasser, index) => (
                                                            <MenuItem
                                                                key={`suggestion-${index}`}
                                                                component="div"
                                                                style={{height: "60px"}}
                                                                onClick={() => {
                                                                    setActive(false);
                                                                    setSearch('');
                                                                    if (!values.canvassers.find(item => canvasser.id === item.id)) {
                                                                        const assigned = Boolean(assignedCanvassers[canvasser.id]);

                                                                        setFieldValue('canvassers', [...values.canvassers, {
                                                                            ...canvasser, assigned
                                                                        }], false);
                                                                    }
                                                                }}>
                                                                <div className="flex align-items-center">
                                                                    {canvasser.lastName}, {canvasser.firstName}
                                                                </div>
                                                            </MenuItem>
                                                        ))
                                                        :
                                                        <div className="autocomplete-no-results">
                                                            No Results.
                                                        </div>
                                                    }
                                                </Paper>
                                            </Popper>
                                            <div className="flex flex-wrap marginTop20">
                                                {values.canvassers.length ?
                                                    values.canvassers.map((canvasser, index) => (
                                                        <Chip
                                                            key={canvasser.id}
                                                            color="default"
                                                            label={`${canvasser.lastName}, ${canvasser.firstName}`}
                                                            className="marginRight15 marginBottom10"
                                                            avatar={canvasser.assigned ? <Tooltip title={CANVASSER_WARNING}><Warning /></Tooltip> : null}
                                                            onDelete={() => {
                                                                const newValues = [...values.canvassers];
                                                                newValues.splice(index, 1);
                                                                setFieldValue('canvassers', newValues, false);
                                                            }}  />
                                                    ))
                                                    :
                                                    <div>
                                                        No Canvassers Assigned.
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="turf-details-section-header">
                                            Download to Tablet App:
                                        </div>
                                        <div className="flex">
                                            <div className="marginRight30">
                                                Past Supporters
                                                <Switch
                                                    checked={values.downloadSupporters || false}
                                                    onChange={event => setFieldValue('downloadSupporters', event.target.checked, false)}
                                                    color="secondary" />
                                            </div>
                                            <div>
                                                Callbacks
                                                <Switch
                                                    checked={values.downloadCallbacks || false}
                                                    onChange={event => setFieldValue('downloadCallbacks', event.target.checked, false)}
                                                    color="secondary" />
                                            </div>
                                        </div>
                                    </form>
                                )}}
                        </Formik>
                    }
                </>
                :
                <div>
                    <div className="page-header">
                        Confirm Delete
                    </div>
                    <div className="marginTop20">
                        Are you sure you want to delete this turf?
                    </div>
                    <div className="flex marginTop20">
                        <div className="marginRight20">
                            <Button variant="text" color="primary" onClick={() => setShowConfirmDelete(false)}>
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button variant="contained" color="secondary" onClick={onDelete}>
                                <Delete className="marginRight10" /> Delete
                            </Button>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
};

export default TurfDetails;