import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import {KeyboardDatePicker} from '@material-ui/pickers';
import {format, setHours} from 'date-fns';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import OfficeSelect from "../_common/officeSelect";
import AutoSubmitTextField from "../_common/textfields/autoSubmit";
import useFilters from "../../hooks/useFilters";
import { loadCallbacks, deleteCallbacks } from '../../redux/callbacks';

import './index.css';

const FIELDS = [
    {label: "Date Created", format: callback => format(callback.createdAt, "M/d/yyyy")},
    {label: "Time Created", format: callback => format(callback.createdAt, "HH:mm:ss.SSSxxx"), hidden: true},
    {label: "Address", format: callback => `${callback.houseNumber} ${callback.streetName} ${callback.address?.line2 || ""}`},
    {label: "City", format: callback => callback.address.city},
    {label: "State", format: callback => callback.address.stateCode },
    {label: "Zip", format: callback => callback.address.zip},
    {label: "Canvasser ID", format: callback => callback.canvasser.canvasserId, hidden: false },
    {label: "Field Canvasser", format: callback => `${callback.canvasser.firstName} ${callback.canvasser.lastName}` },
    {label: "Office", format: callback => callback.canvasser.office },
];

const CallbacksSearch = ({ onSearchCompleted }) => {
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(setHours(new Date(), 0));
    const [endDate, setEndDate] = useState(setHours(new Date(), 23));
    const [office, setOffice] = useState('');
    const [canvasserId, setCanvasserId] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [callbacks, setCallbacks] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const filteredCallbacks = useFilters(callbacks, [
        callback => office.trim().length ? callback.canvasser.office === office : true,
        callback => canvasserId.trim().length ? callback.canvasser.canvasserId.toLowerCase().indexOf(canvasserId.toLowerCase()) > -1 : true,
        callback => city.trim().length ? callback.address.city.toLowerCase().indexOf(city.toLowerCase()) > -1 : true,
        callback => zip.trim().length ? callback.address.zip.toLowerCase().indexOf(zip.toLowerCase()) > -1 : true
    ], [office, canvasserId, city, zip, callbacks]);

    useEffect(() => {
        if (filteredCallbacks && filteredCallbacks.length) {
            onSearchCompleted(filteredCallbacks);
        }
    }, [filteredCallbacks, onSearchCompleted]);

    const onSearch = () => {
        setLoading(true);
        dispatch(loadCallbacks(startDate, endDate, enqueueSnackbar, results => {
            setCallbacks(results);
            setLoading(false);
        }))
    };

    const onDelete = () => {
        setDeleting(true);
        dispatch(deleteCallbacks(filteredCallbacks.map(callback => callback.id), setDeleting, enqueueSnackbar, () => {
            setCallbacks([]);
        }));
    };

    return (
        <div className="callbacks-search-container">
            <Card className="padding20">
                <CardContent>
                    <div className="flex marginTop20">
                        <div>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Start Date"
                                value={startDate}
                                onChange={date => setStartDate(date)}
                                autoOk={true}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                        <div className="marginLeft30">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="End Date"
                                value={endDate}
                                onChange={date => {
                                    date.setHours(23);
                                    setEndDate(date)
                                }}
                                autoOk={true}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                        <div className="marginLeft30 flex align-end callbacks-office-select">
                            {loading ?
                                <CircularProgress size={35} />
                                :
                                <Button variant="contained" color="secondary" onClick={onSearch}>Search</Button>
                            }
                        </div>
                    </div>
                    <div className="flex marginTop20">
                        <div className="flex1">
                            <OfficeSelect
                                value={office}
                                onChange={event => setOffice(event.target.value)}
                                progressSize={35} />
                        </div>
                        <div className="marginLeft30 flex1">
                            <AutoSubmitTextField
                                name="canvasserId"
                                placeholder="tce-18231"
                                label="Canvasser ID"
                                onChange={event => {
                                    setCanvasserId(event.target.value);
                                }}
                                value={canvasserId}
                                fullWidth={true} />
                        </div>
                        <div className="marginLeft30 flex1">
                            <AutoSubmitTextField
                                name="city"
                                placeholder="City"
                                label="City"
                                onChange={event => setCity(event.target.value)}
                                value={city}
                                fullWidth={true} />
                        </div>
                        <div className="marginLeft30 flex1">
                            <AutoSubmitTextField
                                id="zip"
                                placeholder="Zip"
                                label="Zip"
                                onChange={event => setZip(event.target.value)}
                                value={zip}
                                fullWidth={true} />
                        </div>
                    </div>
                </CardContent>
            </Card>
            <div className="marginTop30">
                {filteredCallbacks && filteredCallbacks.length ?
                    <div>
                        <div className="marginTop30 flex">
                            <div className="marginRight20">
                                <Checkbox checked={confirm} onChange={e => setConfirm(e.target.checked)} /> Delete {filteredCallbacks.length} Callback(s)
                            </div>
                            <div className="flex center">
                                {deleting ?
                                    <CircularProgress size={35} />
                                    :
                                    <Button variant="contained" color="secondary" disabled={!confirm} onClick={onDelete}>
                                        <DeleteIcon className="marginRight5"/> Delete Callbacks
                                    </Button>
                                }
                            </div>
                        </div>
                        <Paper className="marginTop30">
                            <div className="overflow-x-scroll">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {FIELDS.map((field, index) => (
                                                !field.hidden ?
                                                    <TableCell key={`header-${index}`} style={{textTransform: 'capitalize', cursor: 'pointer', fontWeight: 'bold'}}>
                                                        {field.label}
                                                    </TableCell>
                                                    :
                                                    null
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredCallbacks.map((callback, index) => (
                                            <TableRow key={`row-${index}`}>
                                                {FIELDS.map((field, fieldIndex) => (
                                                    !field.hidden ?
                                                        <TableCell key={`field-${index}-${fieldIndex}`}>
                                                            {field.key ? callback[field.key] : field.format(callback)}
                                                        </TableCell>
                                                        :
                                                        null
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </div>
                    :
                    "No callbacks to display."
                }
            </div>
        </div>
    )
};

CallbacksSearch.propTypes = {
    onSearchCompleted: PropTypes.func.isRequired,
};

export default CallbacksSearch;