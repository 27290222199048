import {v4 as uuidv4} from 'uuid';

import {createSlice} from "@reduxjs/toolkit";

const INITIAL_STATE = {
    campaignsLoading: false,
    campaignLoading: false,
    campaignSaving: false,
    campaignMap: {},
    lastToggle: null,
};

const campaignsSlice = createSlice({
    name: 'campaigns',
    initialState: INITIAL_STATE,
    reducers: {
        campaignsLoading(state, action) {
            state.campaignsLoading = action.payload;
        },
        campaignLoading(state, action) {
            state.campaignLoading = action.payload;
        },
        campaignSaving(state, action) {
            state.campaignSaving = action.payload;
        },
        campaignsSuccess(state, action) {
            state.campaignsLoading = false;
            state.campaignMap = action.payload.reduce((ret, campaign) => {
                if (campaign.survey) {
                    campaign.survey.forEach(question => {
                        question.id = uuidv4();
                    })
                }
                return {
                    ...ret,
                    [campaign.id]: campaign
                }
            }, state.campaignMap);
        },
        campaignSuccess(state, action) {
            state.campaignLoading = false;
            state.campaignSaving = false;
            state.campaignMap[action.payload.id] = action.payload;
        },
        enableACampaign(state, action) {
            state.lastToggle = new Date().getTime();
            state.campaignMap[action.payload].disabled = false;
        },
        disableACampaign(state, action) {
            state.lastToggle = new Date().getTime();
            state.campaignMap[action.payload].disabled = true;
        }
    }
});

export const { campaignsLoading, campaignLoading, campaignSaving, campaignsSuccess, campaignSuccess, enableACampaign, disableACampaign } = campaignsSlice.actions;

export default campaignsSlice.reducer;

// CUSTOM THUNK ACTIONS

export const loadCampaigns = () => (
    (dispatch, getState, { api }) => {
        dispatch(campaignsLoading(true));
        return api.getCampaigns()
            .then(campaigns => {
                return dispatch(campaignsSuccess(campaigns));
            })
            .catch(err => {
                console.error(err);
                return dispatch(campaignsLoading(false));
            })
    }
);

export const enableCampaign = (campaign, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return dispatch(saveCampaign({ ...campaign, disabled: false }, null, null, enqueueSnackbar))
            .then(() => {
                return dispatch(enableACampaign(campaign.id));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(campaignSaving(false))
            });
    }
);

export const disableCampaign = (campaign, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return dispatch(saveCampaign({ ...campaign, disabled: true }, null, null, enqueueSnackbar))
            .then(() => {
                return dispatch(disableACampaign(campaign.id));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(campaignSaving(false))
            });
    }
);

export const loadCampaign = (campaignId) => (
    (dispatch, getState, { api }) => {
        dispatch(campaignLoading(true));
        return api.getCampaign(campaignId)
            .then(campaign => {
                return dispatch(campaignSuccess(campaign));
            })
            .catch(err => {
                console.error(err);
                return dispatch(campaignLoading(false));
            })
    }
);

export const saveCampaign = (campaign, goToNewRecord, goBack, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(campaignSaving(true));
        return api.campaigns.save(campaign)
            .then(result => {
                if (!campaign.id && goToNewRecord) {
                    goToNewRecord(result.id);
                } else if (campaign.id && goBack) {
                    goBack();
                }
                return dispatch(campaignSuccess(result));
            })
            .catch(err => {
                console.error(err);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
                return dispatch(campaignSaving(false));
            })
    }
);

export const uploadFile = (file, path, callback) => (
    (dispatch, getState, { api }) => {
        return api.uploadFile(path, file)
            .then(downloadUrl => callback(downloadUrl));
    }
);

export const uploadSupportStatement = (file, campaignId, callback) => (
    (dispatch, getState, { api }) => {
        const parts = file.name.split(".");
        const extension = parts[parts.length-1];
        return dispatch(uploadFile(file, `campaigns/${campaignId}/support_statement.${extension}`, callback));
    }
);

export const uploadInfographic = (file, campaignId, callback) => (
    (dispatch, getState, { api }) => {
        return dispatch(uploadFile(file, `campaigns/${campaignId}/infographics/${file.name}`, callback));
    }
);

export const duplicateCampaign = (campaign) => (
    (dispatch, getState, { api }) => {
        const duplicate = { ...campaign, campaignName: `Copy of ${campaign.campaignName}` };
        delete duplicate.id;
        return dispatch(saveCampaign(duplicate));
    }
);