import {useMemo} from 'react';

function useFilters(records, filters, dependencies) {
    return useMemo(() => {
        if (records) {
            return filters.reduce((ret, filter) => {
                return ret.filter(filter);
            }, records);
        }
        return null;
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useFilters;