import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    turfsLoading: false,
    turfSaving: false,
    turfMap: {},
    lastUpdate: null,
};

const turfsSlice = createSlice({
    name: 'turfs',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.turfsLoading = action.payload || true;
        },
        turfsSuccess(state, action) {
            state.turfsLoading = false;
            state.turfMap = action.payload.reduce((ret, turf) => ({
                ...ret,
                [turf.id]: turf
            }), state.turfMap);
        },
        turfSuccess(state, action) {
            state.turfSaving = false;
            state.turfMap[action.payload.id] = action.payload;
            state.lastUpdate = new Date().getTime();

            // TODO: We need to remove the Canvassers assigned in this Turf from any other turfs they might be assigned to
            // TODO: In memory. There's a cloud function that takes care of that for us in the database.
            const turfs = Object.values(state.turfMap).filter(turf => turf.id !== action.payload.id);
            action.payload.canvassers.map(canvasser => canvasser.id).forEach(canvasserId => {
                turfs.forEach(turf => {
                    const index = turf.canvassers.map(turfCanvasser => turfCanvasser.id).indexOf(canvasserId);
                    if (index > -1) {
                        //KBM - I don't like this....
                        turf.canvassers.splice(index, 1);
                    }
                })
            });
        },
        turfDeleted(state, action) {
            delete state.turfMap[action.payload];
        }
    }
});

export const { loading, turfsSuccess, turfSuccess, turfDeleted } = turfsSlice.actions;

export default turfsSlice.reducer;

// CUSTOM THUNK ACTIONS

export const loadTurfs = () => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.getAllTurfs()
            .then(turfs => {
                return dispatch(turfsSuccess(turfs));
            })
            .catch(err => {
                dispatch(loading(false));
                console.error("error fetching turfs", err);
            })
    }
);

export const saveTurf = (turf, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.saveTurf(turf)
            .then(result => {
                enqueueSnackbar("Turf Saved", { variant: "success" });
                return dispatch(turfSuccess(result));
            })
            .catch(err => {
                console.error("error saving turf", err);
            });
    }
);

export const deleteTurf = (turfId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.deleteTurf(turfId)
            .then(() => {
                enqueueSnackbar("Turf Deleted", { variant: "success" });
                return dispatch(turfDeleted(turfId));
            })
            .catch(err => {
                console.error("error deleting turf", err);
                enqueueSnackbar(err.message, { variant: "error" });
            })
    }
);