import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import Nav from './nav';
import Campaigns from "../campaigns";
import Canvassers from "../canvassers";
import Reports from "../reports";
import Donations from "../donations";
import StaticFiles from "../staticFiles";
import Turfs from "../turfs";
import Supporters from '../supporters';
import {logout} from "../../redux/login/actions";
import {loadOrgs} from "../../redux/organizations";

import './index.css';

const Home = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // KBM - Load orgs at the top, because we might need them elsewhere, such as Reports for mapping orgId to name
    useEffect(() => {
        dispatch(loadOrgs(enqueueSnackbar))
    }, [dispatch, enqueueSnackbar]);

    return (
        <Router>
            <div className="flex">
                <Hidden mdDown>
                    <div className="home-nav-container">
                        <Nav />
                    </div>
                </Hidden>
                <Hidden lgUp>
                    <Drawer anchor="left" open={showDrawer} onClose={() => setShowDrawer(false)}>
                        <Nav onClose={() => setShowDrawer(false)} />
                    </Drawer>
                </Hidden>
                <div className="home-content-container relative flex1 flex column overflow-x-scroll">
                    <AppBar position="static" color="primary">
                        <Toolbar disableGutters={true}>
                            <div className="home-toolbar-title-container text-align-left flex w100">
                                <Hidden lgUp>
                                    <IconButton onClick={() => setShowDrawer(true)}>
                                        <MenuIcon htmlColor="#ffffff" />
                                    </IconButton>
                                </Hidden>
                                <div className="home-toolbar-title flex align-items-center flex1">
                                    <Switch>
                                        <Route path="/campaigns" render={() => "Campaigns"} />
                                        <Route path="/canvassers" render={() => "Canvassers"} />
                                        <Route path="/reports" render={() => "Reports"} />
                                        <Route path="/donations" render={() => "Donations"} />
                                        <Route path="/supporters" render={() => "Supporters"} />
                                        <Route path="/static" render={() => "Static Information"} />
                                        <Route path="/turfs" render={() => "Turf Builder"} />
                                        <Route render={() => "Campaigns"} />
                                    </Switch>
                                </div>
                                <div className="marginRight25">
                                    <Button color="default" variant="text" onClick={() => dispatch(logout())}>
                                        <span className="white">Logout</span>
                                    </Button>
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div className="flex1 scrollY home-main-content">
                        <Switch>
                            <Route path="/campaigns/:campaignId?" component={Campaigns} />
                            <Route path="/canvassers/:canvasserId?" component={Canvassers} />
                            <Route path="/reports" component={Reports} />
                            <Route path="/donations" component={Donations} />
                            <Route path="/supporters" component={Supporters} />
                            <Route path="/static" component={StaticFiles} />
                            <Route path="/turfs" component={Turfs} />
                            <Route path="/" render={() => <Redirect to="/campaigns" />} />
                        </Switch>
                    </div>
                </div>
            </div>
        </Router>
    )
};

export default Home;