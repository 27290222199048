import {
    SUPPORTERS_LOADING,
    SUPPORTERS_SUCCESS,
    SUPPORTERS_IMPORTED,
    SUPPORTERS_IMPORT_ERROR,
    FLAGGED_SUPPORTERS_LOADING,
    FLAGGED_SUPPORTERS_SUCCESS,
} from "./types";

export const searchSupporters = (lat, lng, radius, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: SUPPORTERS_LOADING });
        return api.searchSupporters(lat, lng, radius)
            .then(supporters => {
                console.log("supporters", supporters);
                return dispatch({ type: SUPPORTERS_SUCCESS, payload: supporters });
            })
            .catch(err => {
                enqueueSnackbar(err.message, { variant: "error" });
                console.error("error searching supporters", err);
            })
    }
);

export const importSupporters = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: SUPPORTERS_LOADING });
        return api.importSupporters()
            .then(importDate => {
                dispatch({type: SUPPORTERS_IMPORTED, payload: importDate});
            })
            .catch(err => {
                dispatch({type: SUPPORTERS_IMPORT_ERROR});
                enqueueSnackbar(err.message, { variant: "error" });
                console.error("error importing supporters", err);
            })
    }
);

export const getLastImportDate = () => (
    (dispatch, getState, { api }) => {
        return api.getLastImportDate()
            .then(importDate => {
                dispatch({type: SUPPORTERS_IMPORTED, payload: importDate});
            })
            .catch(err => {
                console.error("error getting last import date", err);
            })
    }
);

export const getFlaggedSupportersByDate = (startDate, endDate, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: FLAGGED_SUPPORTERS_LOADING });
        return api.getFlaggedSupportersByDate(startDate, endDate)
            .then(supporters => {
                dispatch({type: FLAGGED_SUPPORTERS_SUCCESS, payload: supporters});
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error("error getting flagged supporters", err);
            })
    }
);

export const exportFlaggedSupporters = (records, setExporting, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.exportFlaggedSupporters(records)
            .then(result => {
                setExporting(false);
                window.open(result.data);
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error("error exporting flagged supporters", err);
                setExporting(false);
            })
    }
);

export const deleteSupporters = (tabName, setLoading, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.supporters.deleteSupporters(tabName)
            .then(result => {
                setLoading(false);
                enqueueSnackbar("Successfully Deleted", {variant: 'success'});
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error("error deleting supporters", err);
                setLoading(false);
            })
    }
);