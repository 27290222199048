import React, { useEffect, useState, useMemo } from 'react';
import {useDispatch} from "react-redux";
import {Link, useParams} from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";

import CanvasserItem from "./item";
import CanvasserDetails from "./details";
import Loading from "../_common/loading";
import FullscreenDialog from "../_common/dialog/fullScreen";
import YellowFab from "../_common/yellowFab";
import {loadCanvassers} from "../../redux/canvassers/actions";
import {useCanvassersSelector} from "../../redux/canvassers/selectors";
import useFilters from "../../hooks/useFilters";

import searchIco from '../../img/icn-search.svg';

import './index.css';

const Canvassers = () => {
    const [ showDisabled, setShowDisabled ] = useState(false);
    const [ search, setSearch ] = useState('');
    const { canvassersLoading, canvasserIds, lastToggle, canvassers } = useCanvassersSelector();
    const { canvasserId } = useParams();
    const dispatch = useDispatch();

    const dependencies = [lastToggle, canvasserIds ? canvasserIds.length : 0];

    const enabledCanvassers = useMemo(() => canvassers ? canvassers.filter(canvasser => !canvasser.disabled) : null, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
    const disabledCanvassers = useMemo(() => canvassers ? canvassers.filter(canvasser => canvasser.disabled) : null, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

    const filteredEnabled = useFilters(enabledCanvassers, [
        canvasser =>  _searchFilter(canvasser, search)
    ], [search, enabledCanvassers]);

    const filteredDisabled = useFilters(disabledCanvassers, [
        canvasser =>  _searchFilter(canvasser, search)
    ], [search, disabledCanvassers]);

    useEffect(() => {
        dispatch(loadCanvassers());
    }, [dispatch]);

    return (
        <div className="canvassers-container">
            {canvassersLoading || !enabledCanvassers || !disabledCanvassers ?
                <Loading size={45} />
                :
                <div>
                    <Link to={`/canvassers/add`} className="canvassers-add-fab">
                        <YellowFab aria-label="Add" onClick={() => {}}>
                            <AddIcon htmlColor="#ffffff" />
                        </YellowFab>
                    </Link>
                    <div className="marginBottom30 flex align-end">
                        <div className="flex">
                            <div className="flex1 alignLeft canvassers-search-card">
                                <TextField
                                    name="search"
                                    label="Search"
                                    type="search"
                                    placeholder="Search Canvassers"
                                    hint="search by Name, Phone #, or Canvasser ID"
                                    value={search}
                                    onChange={event => setSearch(event.target.value.toLowerCase())}
                                    InputProps={{startAdornment: <img src={searchIco} alt="search" className="marginRight10" />}}
                                    fullWidth />
                            </div>
                        </div>
                        <div className="flex align-items-center marginLeft30">
                            <Checkbox checked={showDisabled} onChange={event => setShowDisabled(event.target.checked)} />
                            Show Deactivated Canvassers
                        </div>

                    </div>
                    {showDisabled &&
                    <>
                        <div className="page-header">
                            Deactivated Canvassers
                        </div>
                        <div className="marginBottom30">
                            {filteredDisabled && filteredDisabled.length ?
                                filteredDisabled.map(canvasser => (
                                    <CanvasserItem canvasserId={canvasser.id} key={`canvasser-item-${canvasser.id}`} />
                                ))
                                :
                                <div className="marginTop20">
                                    No deactivated canvasseres.
                                </div>
                            }
                        </div>
                    </>
                    }
                    <div className="page-header">
                        Active Canvassers
                    </div>
                    <div className="marginBottom30">
                        {filteredEnabled && filteredEnabled.length ?
                            filteredEnabled.map(canvasser => (
                                <CanvasserItem canvasserId={canvasser.id} key={`canvasser-item-${canvasser.id}`} />
                            ))
                            :
                            <div className="marginTop20">
                                No canvassers.
                            </div>
                        }
                    </div>
                </div>
            }
            <FullscreenDialog onClose={() => {}} open={Boolean(canvasserId)}>
                <CanvasserDetails canvasserId={canvasserId} />
            </FullscreenDialog>
        </div>
    )
};

function _searchFilter(canvasser, search) {
    return canvasser.firstName.toLowerCase().indexOf(search) > -1 ||
        canvasser.lastName.toLowerCase().indexOf(search) > -1 ||
        canvasser.phoneNumber.indexOf(search) > -1 ||
        canvasser.canvasserId.toLowerCase().indexOf(search) > -1
}

export default Canvassers;