import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

import './index.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Standard dialog for showing prompts / alerts / messages
 * @param title     The title of the dialog
 * @param open      True if the dialog is open
 * @param onClose   Function for dismissing the dialog
 * @param children  Child components to render
 * @param actions   Items to render as Dialog Actions (usually buttons)
 */
const TCEDialog = ({title, open, onClose, children, actions, maxWidth, showTitle}) => (
    <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        maxWidth={maxWidth}>
        {showTitle &&
            <DialogTitle>
                {title}
            </DialogTitle>
        }
        <DialogContent>
            {children}
        </DialogContent>
        <DialogActions>
            {actions ? actions.map((action, index) => (
                <Button variant="text" color="secondary" onClick={action.onClick} key={`dialog-action-${index}`}>{action.label}</Button>
            )) : null}
        </DialogActions>
    </Dialog>
);

TCEDialog.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.any,
    actions: PropTypes.array,
};

TCEDialog.defaultProps = {
    maxWidth: "sm",
    showTitle: true
};

export default TCEDialog;