import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import TCEApi from '@devsontap/tce-api';

function initFirebase() {
    let config = isDev() ?
        { // Dev
            apiKey: "AIzaSyD24KH6iEea44uHsw-CpsefDrgM5wjHAFc",
            authDomain: "tce-canvassers-dev.firebaseapp.com",
            databaseURL: "https://tce-canvassers-dev.firebaseio.com",
            storageBucket: "tce-canvassers-dev.appspot.com",
            projectId: "tce-canvassers-dev"
        }
        :
        { // Prod
            apiKey: "AIzaSyCELP40X2MXGwpkpKv577DqiS2zMAgOWAQ",
            authDomain: "tce-canvassers.firebaseapp.com",
            databaseURL: "https://tce-canvassers.firebaseio.com",
            storageBucket: "tce-canvassers.appspot.com",
            projectId: "tce-canvassers"
        };
    firebase.initializeApp(config);
    // KBM - For testing
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

export function isDev() {
    return document.domain !== 'cms.texasenvironment.org' && document.domain !== 'tce-cms.web.app' && document.domain !== 'tce-cms.firebaseapp.com';
}

initFirebase();
const api = new TCEApi(firebase.database, firebase.firestore, firebase.functions, firebase.storage);

api.login = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
};

api.logout = () => {
    return firebase.auth().signOut();
};

api.sendForgotPassword = (email) => {
    return firebase.auth().sendPasswordResetEmail(email);
};

/**
 * Firebase Auth callback when state changes
 * @param onAuthChanged
 */
// api.onAuthStateChanged = (onAuthChanged) => {
//     firebase.auth().onAuthStateChanged(onAuthChanged);
// };

export default api;