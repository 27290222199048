import React from 'react';
import { getDefaultMiddleware, configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';

import api from './api';
import reducers from './redux';
import Home from './components/home';
import Login from "./components/login";
import AuthProvider from "./components/_common/authProvider";

import './App.css';
import '@devsontap/dot-react-common/core/css/atomic.css';

/**
 * This is the Material-UI theme config for the Application
 */
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00b4e8',
            // dark: '#c20000',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#4db04a',
            contrastText: '#ffffff',
        },
        // error: will use the default color
    },
    typography: {
        fontFamily: [
            'Roboto, sans-serif',
            'Lato, sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        useNextVariants: true
    },
    overrides: {
        MuiButton: {
            // Name of the styleSheet
            root: {
                color: 'white'
            },

        },
        MuiFab: {
            primary: {
                backgroundColor: '#ffd400'
            }
        },
        MuiListItem: {
            button: {
                paddingTop: "0px",
                paddingBottom: "0px"
            }
        }
    }
});

/**
 * Create a Redux store with Thunk middleware
 */
// const createStoreWithMiddleware = applyMiddleware(reduxThunk.withExtraArgument({ api }))(createStore);
// const store = createStoreWithMiddleware(reducers,
//     process.env.NODE_ENV === "development" &&
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

/**
 * Create a Redux store and configure extra argument for Thunk middleware
 */
const customizedMiddleware = getDefaultMiddleware({
    thunk: {
        extraArgument: { api }
    },
    serializableCheck: false
});

const store = configureStore({
    reducer: reducers,
    middleware: customizedMiddleware
});

const App = () => {
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Provider store={store}>
                        <div className="App">
                            <AuthProvider unAuthed={<Login />}>
                                <Home />
                            </AuthProvider>
                        </div>
                    </Provider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </SnackbarProvider>

    );
};

export default App;
