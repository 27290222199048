import {
    USER_AUTHED,
    USER_LOGOUT
} from "./types";

export const login = (email, password, setLoading, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.login(email, password)
            .catch(err => {
                setLoading(false);
                enqueueSnackbar("The email and password entered are not valid. Please try again.", { variant: 'error' });
            });
    }
);

export const logout = () => (
    (dispatch, getState, { api }) => {
        return api.logout();
    }
);

export const sendForgotPassword = (email, setLoading, setShowConfirmation, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.sendForgotPassword(email)
            .then(() => {
                setLoading(false);
                setShowConfirmation(true);
            })
            .catch(err => {
                setLoading(false);
                enqueueSnackbar("Error initiating Password Reset. Please try again.", { variant: 'error' });
            });
    }
);

export const userAuthed = (claims) => ({ type: USER_AUTHED, payload: claims });

export const userUnauthed = () => ({ type: USER_LOGOUT });