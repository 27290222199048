import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    loading: false,
    orgMap: {}
};

const organizationsSlice = createSlice({
    name: 'organizations',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        orgsSuccess(state, action) {
            state.loading = false;
            state.orgMap = action.payload.reduce((ret, org) => ({...ret, [org.id]: org }), state.orgMap);
        },
    }
});

export const { loading, orgsSuccess } = organizationsSlice.actions;

export default organizationsSlice.reducer;

// CUSTOM THUNK ACTIONS

export const loadOrgs = (enqueueSnackbar, onSuccess) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.organizations.getAll()
            .then(orgs => {
                if (onSuccess) {
                    onSuccess(orgs)
                }
                return dispatch(orgsSuccess(orgs));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            })
    }
);

export const saveOrg = (org, setLoading, enqueueSnackbar, onSuccess) => (
    (dispatch, getState, { api }) => {
        return api.organizations.save(org)
            .then(result => {
                dispatch(orgsSuccess([result]));
                if (onSuccess) {
                    onSuccess(result);
                }
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            });
    }
);