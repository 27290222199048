import { orgsSuccess } from "../organizations";

export const updateStaticFile = (file, key, orgId, callback) => (
    async (dispatch, getState, { api }) => {
        const parts = file.name.split(".");
        const extension = parts[parts.length-1];
        const downloadUrl = await api.uploadFile(`${orgId}.${key}.${extension}`, file);
        const org = await api.organizations.get(orgId);
        console.log("org", org, key, downloadUrl);
        const updatedOrg = await api.organizations.save({
            ...org,
            [key]: downloadUrl
        });
        dispatch(orgsSuccess([updatedOrg]));
        callback(downloadUrl);
    }
);