import { combineReducers } from 'redux'
import { useSelector, shallowEqual } from 'react-redux';

import callbacks from './callbacks';
import campaigns from './campaigns';
import canvassers from './canvassers';
import login from './login';
import turfs from './turfs';
import supporters from './supporters';
import organizations from './organizations';

const RootReducer = combineReducers({
    callbacks,
    campaigns,
    canvassers,
    login,
    turfs,
    supporters,
    organizations
});

/**
 * Helper function for wrapping a selector with a shallowEqual equality function
 */
export const useShallowEqualSelector = (selector) => {
    return useSelector(selector, shallowEqual);
};


export default RootReducer;