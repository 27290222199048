import {getLocal, saveLocal} from "@devsontap/dot-react-common/core/utils/localStorage";

import {
    USER_AUTHED,
    USER_LOGOUT
} from "./types";

const KEY_LOGGED_IN = "loggedIn";
const KEY_CLAIMS = "claims";

const INITIAL_STATE = {
    loggedIn: getLocal(KEY_LOGGED_IN, false) === "true" || false, // read from disk
    claims: getLocal(KEY_CLAIMS, true) || {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_AUTHED:
            saveLocal(KEY_CLAIMS, action.payload, true);
            saveLocal(KEY_LOGGED_IN, true, false);
            return {
                ...state,
                loggedIn: true,
                claims: action.payload
            };
        case USER_LOGOUT:
            saveLocal(KEY_CLAIMS, {}, true);
            saveLocal(KEY_LOGGED_IN, false, false);
            return {
                ...state,
                loggedIn: false,
                claims: {}
            };
        default:
            return state;
    }
}



