import React from 'react';
import Button from '@material-ui/core/Button';
import arrayMove from "array-move";
import {SurveyQuestionTypes} from '@devsontap/tce-api/core/models/enums/index';
import {v4 as uuidv4} from 'uuid';

import SurveyItem from "./item";

import './index.css';
import CustomFormikField from "../../../_common/formik/customField";

const Survey = ({survey, setFieldValue}) => {
    const sortQuestions = (from, to) => {
        if (to > -1 && to < survey.length) {
            // arrayMove(survey, from, to);
            setFieldValue('survey', arrayMove(survey, from, to), false);
        }
    };

    const addQuestion = () => {
        setFieldValue(`survey[${survey.length}]`, {
            id: uuidv4(),
            questionText: '',
            choices: [],
            required: false,
            type: SurveyQuestionTypes[0].value
        }, false);
    };

    const deleteQuestion = (index) => {
        const update = [...survey];
        update.splice(index, 1);
        setFieldValue('survey', update, false);
        // updateSurvey(update);
    };

    return (
        <div>
            <div>
                {survey.map((question, index) => (
                    <CustomFormikField
                        CustomComponent={SurveyItem}
                        name={`survey[${index}]`}
                        key={question.id}
                        {...{question, index, sortQuestions, deleteQuestion, setFieldValue}}
                        hideError />
                ))}
            </div>
            <div className="flex justify-content-center">
                <Button variant="contained" color="secondary" onClick={addQuestion}>
                    + Add Survey Question
                </Button>
            </div>
        </div>
    )
};

export default Survey;