import React, {useState} from 'react'
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import NICADialog from '../dialog';

import './index.css';

/**
 * Renders a drag-and-drop area that users can drag files into
 *
 * TODO: Make button work
 *
 * @param accept        An array of file types to accept
 * @param onDrop        The callback function triggered when files are dropped or picked from the file input
 * @param loading       If true the widget renders a CircularProgress bar
 * @returns {XML}
 */
const DropZone = ({accept, onDrop, loading, outline}) => {
    const [showNotSupportedDialog, setShowNotSupportedDialog] = useState(false);

    // const onDropCallback = useCallback(acceptedFiles => {
    //     onDrop(acceptedFiles);
    // }, []);  // eslint-disable-line react-hooks/exhaustive-deps



    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept,
        onDropAccepted: onDrop,
        onDropRejected: () => { setShowNotSupportedDialog(true); }
    });

    return (
        <div {...getRootProps()} className={`drop-zone-container flex center ${outline ? " drop-zone-container-outlined" : ""}`}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <div className="drop-zone-title">
                        You got this :)
                    </div>
                    :
                    <div>
                        {loading ?
                            <CircularProgress size={40}/>
                            :
                            <div className="flex column center">
                                <div className="drop-zone-title">
                                    Drag 'n' drop files here
                                </div>
                                <div className="marginTop20">
                                    -- or --
                                </div>
                                <div className="marginTop20">
                                    <Button variant="contained" color="secondary">click to select files</Button>
                                </div>
                            </div>
                        }
                    </div>
            }
            <NICADialog
                title="File Not Supported"
                open={showNotSupportedDialog}
                onClose={() => { setShowNotSupportedDialog(false) }}
                actions={[{label: 'OK', onClick: event => { event.preventDefault(); setShowNotSupportedDialog(false) }}]}>
                <div>
                    Sorry, the file type chosen is not supported
                </div>
            </NICADialog>
        </div>
    )
};

DropZone.propTypes = {
    accept: PropTypes.string,
    onDrop: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

export default DropZone;

