import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import OpenIcon from "@material-ui/icons/OpenInNew";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, setHours, subDays } from 'date-fns';
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import TCEDialog from "../_common/dialog";
import FlaggedReasonSelect from "../_common/flaggedReasonSelect";
import useFilters from "../../hooks/useFilters";
import {importSupporters, getLastImportDate, getFlaggedSupportersByDate, exportFlaggedSupporters} from "../../redux/supporters/actions";
import {useSupportersSelector} from "../../redux/supporters/selectors";

import './index.css';
import DeleteSupporters from "./delete";

const Supporters = () => {
    const [exporting, setExporting] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [startDate, setStartDate] = useState(subDays(setHours(new Date(), 0), 7));
    const [endDate, setEndDate] = useState(setHours(new Date(), 23));
    const [flaggedReason, setFlaggedReason] = useState('');
    const [zip, setZip] = useState('');
    const {supportersLoading, lastImportDate, flaggedSupporters, flaggedSupportersLoading} = useSupportersSelector();
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLastImportDate());
    }, [dispatch]);

    const filteredSupporters = useFilters(flaggedSupporters, [
        supporter =>  _flaggedReasonFilter(supporter, flaggedReason),
        supporter => _zipFilter(supporter, zip)
    ], [flaggedReason, zip, flaggedSupporters]);

    const onSearch = () => {
        dispatch(getFlaggedSupportersByDate(startDate, endDate, enqueueSnackbar));
    };

    const onExport = () => {
        const records = filteredSupporters.map(supporter => {
            return [
                supporter.contactId,
                supporter.firstName,
                supporter.lastName,
                supporter.address.line1,
                supporter.email,
                supporter.flags.map(flag => format(flag.createdAt.toDate(), "MM/dd/yyyy")).join(", "),
                supporter.flags.map(flag => flag.flaggedBy).join(", "),
                supporter.flags.map(flag => flag.flaggedReason).join(", ")
            ]
        });
        setExporting(true);
        dispatch(exportFlaggedSupporters(records, setExporting, enqueueSnackbar));
    };

    return (
        <div className="reports-container padding30">
            <div className="page-header marginBottom15">Import Supporters</div>
            {!supportersLoading ?
                <div className="flex">
                    <div className="marginRight20">
                        <Button variant="contained" color="secondary" onClick={() => setShowConfirm(true)}>
                            Import Supporters from Google Sheets
                        </Button>
                    </div>
                    <div>
                        <Button variant="text" color="secondary" onClick={() => window.open("https://docs.google.com/spreadsheets/d/1rZ4lfYY_UuLGXLS9qUIpoBjshD4xUNIQrhvxdnfaQ1E/edit#gid=1532842041")}>
                            <OpenIcon className="marginRight5"/> View Import File
                        </Button>
                    </div>
                </div>
                :
                <CircularProgress size={45} />
            }
            {lastImportDate &&
                <div className="marginTop10">
                    Last Imported: {format(lastImportDate, 'MM/dd/yyyy')}
                </div>
            }
            <div className="page-header marginBottom15 marginTop40">Delete Supporters</div>
            <div className="marginBottom15">Specify the Tab Name from the Import File that contains records to delete</div>
            <div style={{ paddingBottom: 20}}>
                <DeleteSupporters />
            </div>
            <Card className="padding20 marginTop30">
                <CardContent>
                    <div className="text-align-left">
                        <div className="page-header">Export Flagged Supporters</div>
                        <div>
                            Select a date range and export.
                        </div>
                    </div>
                    <div className="flex marginTop20">
                        <div>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Start Date"
                                value={startDate}
                                onChange={date => setStartDate(date)}
                                autoOk={true}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                        <div className="marginLeft30">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="End Date"
                                value={endDate}
                                onChange={date => {
                                    date.setHours(23);
                                    setEndDate(date)
                                }}
                                autoOk={true}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                        <div className="marginLeft20 flex align-items-center">
                            {flaggedSupportersLoading ?
                                <CircularProgress size={35} />
                                :
                                <Button variant="contained" color="secondary" onClick={onSearch}>Search</Button>
                            }
                        </div>
                    </div>
                    <div className="marginTop30 flex">
                        <div className="flex1 marginRight25">
                            <FlaggedReasonSelect
                                onChange={event => setFlaggedReason(event.target.value)}
                                value={flaggedReason} />
                        </div>
                        <div className="flex1">
                            <TextField
                                name="zip"
                                label="Zip Code"
                                placeholder="78701"
                                type="tel"
                                value={zip}
                                onChange={event => setZip(event.target.value)}
                                fullWidth />
                        </div>
                        <div className="flex1 flex align-end marginLeft10">
                            <Button variant="text" color="primary" onClick={() => {
                                setZip('');
                                setFlaggedReason('');
                            }}>
                                Clear
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <div className="marginTop30">
                {filteredSupporters && filteredSupporters.length ?
                    <div>
                        <div className="flex justify-end">
                            {exporting ?
                                <CircularProgress size={35} />
                                :
                                <Button variant="contained" color="secondary" onClick={onExport}>Export</Button>
                            }
                        </div>
                        <Paper className="marginTop30">
                            <div className="overflow-x-scroll">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{textTransform: 'capitalize', cursor: 'pointer', fontWeight: 'bold'}}>
                                                Supporter Name
                                            </TableCell>
                                            <TableCell style={{textTransform: 'capitalize', cursor: 'pointer', fontWeight: 'bold'}}>
                                                Address
                                            </TableCell>
                                            <TableCell style={{textTransform: 'capitalize', cursor: 'pointer', fontWeight: 'bold'}}>
                                                City
                                            </TableCell>
                                            <TableCell style={{textTransform: 'capitalize', cursor: 'pointer', fontWeight: 'bold'}}>
                                                Flagged Date
                                            </TableCell>
                                            <TableCell style={{textTransform: 'capitalize', cursor: 'pointer', fontWeight: 'bold'}}>
                                                Flagged By
                                            </TableCell>
                                            <TableCell style={{textTransform: 'capitalize', cursor: 'pointer', fontWeight: 'bold'}}>
                                                Flagged Reasons
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredSupporters.map((record, index) => (
                                            <TableRow key={`row-${index}`}>
                                                <TableCell>
                                                    {record.firstName} {record.lastName}
                                                </TableCell>
                                                <TableCell>
                                                    {record.address.line1}
                                                </TableCell>
                                                <TableCell>
                                                    {record.address.city}
                                                </TableCell>
                                                <TableCell>
                                                    {record.flags.map(flag => format(flag.createdAt.toDate(), "MM/dd/yyyy")).join(", ")}
                                                </TableCell>
                                                <TableCell>
                                                    {record.flags.map(flag => flag.flaggedBy).join(", ")}
                                                </TableCell>
                                                <TableCell>
                                                    {record.flags.map(flag => flag.flaggedReason).join(", ")}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </div>
                    :
                    "No records to display."
                }
            </div>
            <TCEDialog
                onClose={() => setShowConfirm(false)}
                open={showConfirm}
                title="Confirm Import"
                actions={[
                    {label: 'cancel', onClick: () => setShowConfirm(false)},
                    {label: 'import', onClick: () => {
                        setShowConfirm(false);
                        dispatch(importSupporters(enqueueSnackbar));
                    }}
                ]}>
                Are you sure you wish to procede?
            </TCEDialog>
        </div>
    )
};

function _flaggedReasonFilter(supporter, flaggedReason) {
    return flaggedReason ? Boolean(supporter.flags.find(flag => flag.flaggedReason === flaggedReason)) : true;
}

function _zipFilter(supporter, zip) {
    return zip ? supporter.address.zip.includes(zip) : true;
}

export default Supporters;