import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";

import StaticFileUpload from "./item";
import Loading from "../_common/loading";
import OrganizationSelect from "../_common/organizationSelect";
import {updateStaticFile} from "../../redux/staticFiles/actions";
import {orgsSelector} from "../../redux/organizations/selectors";

import './index.css';

const StaticFiles = () => {
    const [orgId, setOrgId] = useState("");
    const dispatch = useDispatch();
    const orgs = useSelector(orgsSelector);
    const currentOrg = useMemo(() => orgs?.find(org => org.id === orgId), [orgId, orgs]);

    // useEffect(() => {
    //     dispatch(loadStaticFiles(staticFiles => {
    //         setFiles(staticFiles);
    //         setLoading(false);
    //     }));
    // }, [dispatch]);

    useEffect(() => {
        if (!orgId && orgs?.length) {
            setOrgId(orgs[0].id);
        }
    }, [orgs, orgId]);

    const updateFile = (key, file, setLoading) => {
        dispatch(updateStaticFile(file, key, orgId, url => {
            setLoading(false);
        }))
    };

    return (
        <div className="static-files-container">
            {!orgs?.length ?
                <Loading size={45} />
                :
                <div>
                    <div className="marginBottom30" style={{width: 300}}>
                        <OrganizationSelect value={orgId} onChange={e => setOrgId(e.target.value)} />
                    </div>
                    <div>
                        <StaticFileUpload
                            fileKey="financialsInfo"
                            downloadUrl={currentOrg?.financialsInfo}
                            updateFile={updateFile}
                            title="Upload the Financial Infographic"
                            description="This infographic can be viewed from the Contribution screen of the app" />
                    </div>
                    <div className="marginTop60">
                        <StaticFileUpload
                            fileKey="strengthInNumbers"
                            downloadUrl={currentOrg?.strengthInNumbers}
                            updateFile={updateFile}
                            title="Upload the Strength In Numbers Infographic"
                            description="This infographic can be viewed from the Strength In Numbers screen of the app" />
                    </div>
                </div>
            }
        </div>
    )
};

export default StaticFiles;