/*global google*/
import {useState, useEffect} from 'react';

function usePolygonManager(map, turfs, turfsIdString, lastPolygon, onPolygonClick) {
    const [polygonMap, setPolygonMap] = useState({});
    const [bounds, setBounds] = useState(null);

    useEffect(() => {
        if (map && turfs && turfs.length) {
            if (lastPolygon) {
                lastPolygon.setMap(null);
            }

            const newBounds = new google.maps.LatLngBounds();
            const ids = turfsIdString.split(",");
            const newMap = turfs
                .filter(turf => {
                    if (polygonMap[turf.id]) {
                        turf.path.forEach(point => newBounds.extend(new google.maps.LatLng(point.lat, point.lng)));
                        return false;
                    }
                    return true;
                })
                .map(turf => {
                    const polygon = new google.maps.Polygon({
                        paths: turf.path,
                        strokeColor: turf.color,
                        fillColor: turf.color,
                        fillOpacity: 0.3,
                        map
                    });
                    polygon.addListener('click', () => onPolygonClick(turf));
                    turf.path.forEach(point => newBounds.extend(new google.maps.LatLng(point.lat, point.lng)));

                    return {id: turf.id, polygon};
                })
                .reduce((ret, polygonItem) => {
                    const {id, polygon} = polygonItem;
                    ret[id] = polygon;
                    return ret;
                }, {});

            Object.keys(polygonMap).forEach(turfId => {
                if (!ids.includes(turfId)) {
                    polygonMap[turfId].setMap(null); // Delete case
                    delete polygonMap[turfId];
                    return;
                }
                newMap[turfId] = polygonMap[turfId]; // Copy over current map items
                newMap[turfId].setMap(map);
            });

            if (!bounds) {
                map.fitBounds(newBounds);
            }

            setPolygonMap(newMap);
            setBounds(newBounds);
        }
    }, [turfsIdString, map]);  // eslint-disable-line react-hooks/exhaustive-deps

    return { polygonMap, bounds };
}

export default usePolygonManager;