import React, { useState } from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { deleteSupporters } from "../../../redux/supporters/actions";

import './index.css';

const DeleteSupporters = () => {
    const [tabName, setTabName] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const onDelete = () => {
        setLoading(true);
        dispatch(deleteSupporters(tabName, setLoading, enqueueSnackbar));
    };

    return (
        <div className="flex">
            <div style={{ width: 300 }}>
                <TextField
                    value={tabName}
                    onChange={e => setTabName(e.target.value)}
                    label="Tab Name"
                    placeholder="09.OCT.2021"
                    fullWidth />
            </div>
            <div className="marginLeft20 flex center">
                { loading ?
                    <CircularProgress size={35} /> :
                    <Button variant="contained" color="secondary" disabled={!tabName.length} onClick={onDelete}>
                        <DeleteIcon className="marginRight5" /> Delete
                    </Button>
                }
            </div>
        </div>
    )
};

export default DeleteSupporters;