import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';

import {FlaggedReasons} from '@devsontap/tce-api/core/models/enums';

const FlaggedReasonSelect = ({name, value, onChange}) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="flaggedReason">Flagged Reason</InputLabel>
            <Select
                name={name}
                value={value}
                onChange={onChange}
                labelId="flaggedReason"
                fullWidth>
                {FlaggedReasons.map((reason, index) => (
                    <MenuItem key={reason.value} value={reason.value}>{reason.value}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};

FlaggedReasonSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

FlaggedReasonSelect.defaultProps = {
    name: "flaggedReason"
};

export default FlaggedReasonSelect;