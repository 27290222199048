import React, {useState} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import * as Yup from 'yup';

import AutoSubmitTextField from "../../_common/textfields/autoSubmit";
import {sendForgotPassword} from "../../../redux/login/actions";

import './index.css'

const ForgotPasswordForm = ({ hideForgotPassword }) => {
    const [loading, setLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {values, errors, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Please enter a valid email").required()
        }),
        onSubmit: values => {
            setLoading(true);
            dispatch(sendForgotPassword(values.email, setLoading, setShowConfirmation, enqueueSnackbar));
        }
    });

    console.log("forgot", errors, touched);

    return (
        <form onSubmit={handleSubmit}>
            <div className="text-align-left login-title roboto">
                Reset Your Password
            </div>
            {!showConfirmation ?
                <>
                    <div className="login-helper-text roboto alignLeft">
                        Enter your email address and we’ll send you a link to reset your password.
                    </div>
                    <div className="login-row">
                        <div>
                            <AutoSubmitTextField
                                name="email"
                                type="email"
                                placeholder="Email Address"
                                label="Email Address"
                                value={values.email}
                                onChange={handleChange}
                                error={errors.email && touched.email}
                                helperText={errors.email && touched.email ? errors.email : null}
                                fullWidth />
                        </div>
                    </div>
                    <div className="login-buttons">
                        {loading ?
                            <div className="flex justify-end">
                                <CircularProgress size={40} />
                            </div>
                            :
                            <div className="flex">
                                <div className="flex1 flex justify-end">
                                    <Button color="primary" onClick={hideForgotPassword}>Cancel</Button>
                                </div>
                                <div className="marginLeft15">
                                    <Button variant="contained" color="secondary" type="submit">Send</Button>
                                </div>
                            </div>
                        }
                    </div>
                </>
                :
                <>
                    <div className="login-helper-text roboto text-align-left">
                        Email sent! Click the link in the email to reset your password.
                    </div>
                    <div className="login-buttons flex justify-end">
                        <Button variant="contained" color="secondary" onClick={hideForgotPassword}>Back to Login</Button>
                    </div>
                </>
            }
        </form>
    )
};

export default ForgotPasswordForm;