import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import './index.css';

/**
 * A standard loading container that centers a CircularProgress bar
 * @param size      The size of the CircularProgress bar to render
 * @returns {XML}
 */
const Loading = ({ size }) => {
    return (
        <div className="loading-container flex center">
            <CircularProgress size={size} />
        </div>
    )
};

Loading.propTypes = {
    size: PropTypes.number.isRequired
};

export default Loading;