import React from 'react';
import {FastField, Field, ErrorMessage} from "formik";

import './index.css';

const CustomFormikField = ({name, CustomComponent, fast, hideError, errorField, errorAsBool, defaultValue, ...otherProps}) => {
    const FormikField = fast ? FastField : Field;
    return (
        <FormikField name={name}>
            {({ field: {value, onChange}, meta: {error, touched} }) => {
                console.log(`formik field ${name} "${value}" ${defaultValue}`);
                return (
                    <>
                        <CustomComponent {...{name, onChange, ...otherProps}} value={value !== null && value !== undefined ? value : defaultValue} error={errorAsBool ? Boolean(touched && error) : (touched && error) + ""} />
                        {!hideError &&
                            <div>
                                <ErrorMessage name={name} render={error => <div className="error">{errorField ? error[errorField] : error.toString()}</div>} />
                            </div>
                        }

                    </>
                )
            }}
        </FormikField>
)};

CustomFormikField.defaultProps = {
    errorAsBool: true
};

export default CustomFormikField;