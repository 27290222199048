import React, {useState} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import * as Yup from 'yup';

import AutoSubmitTextField from "../../_common/textfields/autoSubmit";
import {login} from "../../../redux/login/actions";

import './index.css'

const LoginForm = ({ showForgotPassword }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {values, errors, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Please enter a valid email").required(),
            password: Yup.string().required()
        }),
        onSubmit: values => {
            setLoading(true);
            dispatch(login(values.email, values.password, setLoading, enqueueSnackbar));
        }
    });

    return (
        <form onSubmit={handleSubmit}>
            <div className="text-align-left login-title roboto">
                Sign In
            </div>
            <div className="login-row">
                <div>
                    <AutoSubmitTextField
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        label="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email ? errors.email : null}
                        fullWidth />
                </div>
            </div>
            <div className="login-row">
                <div>
                    <AutoSubmitTextField
                        name="password"
                        type="password"
                        placeholder="Password"
                        label="Password"
                        value={values.password}
                        onChange={handleChange}
                        error={errors.password && touched.password}
                        helperText={errors.password && touched.password ? errors.password : null}
                        fullWidth />
                </div>
            </div>
            <div className="login-buttons">
                {loading ?
                    <div className="flex justify-end">
                        <CircularProgress size={40} />
                    </div>
                    :
                    <div className="flex">
                        <div className="flex1 flex justify-end">
                            <Button color="primary" onClick={showForgotPassword}>Forgot password?</Button>
                        </div>
                        <div className="marginLeft15">
                            <Button variant="contained" color="secondary" type="submit">Login</Button>
                        </div>
                    </div>
                }
            </div>
        </form>
    )
};

export default LoginForm;