import React, {useState} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";

import TCEDialog from "../../_common/dialog";
import {useCanvasserDetailsSelector} from "../../../redux/canvassers/selectors";
import {enableCanvasser, disableCanvasser} from "../../../redux/canvassers/actions";

import './index.css';
import {formatPhoneNumber} from "../../../utils/phone";

const CanvasserItem = ({ canvasserId }) => {
    const [showEnableDisableDialog, setShowEnableDisableDialog] = useState(false);
    const {canvasser} = useCanvasserDetailsSelector(canvasserId);
    const dispatch = useDispatch();

    const { firstName, lastName, office, disabled, phoneNumber, canvasserId: id} = canvasser;

    return (
        <>
            <Card className="canvasser-card">
                <CardContent>
                    <div className="flex">
                        <div className="flex1">
                            <div className="text-align-left roboto canvasser-name">
                                {`${lastName}, ${firstName}`}
                            </div>
                            <div className="text-align-left roboto canvasser-office">
                                {`${office} Office`}
                            </div>
                            <div className="text-align-left roboto canvasser-phone-number">
                                Canvasser ID: {id}
                            </div>
                            <div className="text-align-left roboto canvasser-phone-number marginTop5">
                                Phone #: {formatPhoneNumber(phoneNumber)}
                            </div>
                        </div>
                        <div className="flex center">
                            <div>
                                <Button variant="text" color="secondary" onClick={() => setShowEnableDisableDialog(true)}>
                                    {disabled ? "Activate" : "Deactivate"}
                                </Button>
                            </div>
                            {!disabled &&
                                <div>
                                    <Link to={`/canvassers/${canvasserId}`}>
                                        <Button variant="text" color="secondary">
                                            Edit
                                        </Button>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </CardContent>
            </Card>
            <TCEDialog
                open={showEnableDisableDialog}
                onClose={() => setShowEnableDisableDialog(false)}
                title={`${disabled ? "Activate" : "Deactivate"} Canvasser`}
                actions={[
                    {label: 'Cancel', onClick: () => setShowEnableDisableDialog(false)},
                    {label: disabled ? "Activate" : "Deactivate", onClick: () => {
                        dispatch( disabled ? enableCanvasser(canvasser) : disableCanvasser(canvasser));
                        setShowEnableDisableDialog(false);
                    }}
                ]}>
                Are you sure you want to {disabled ? "activate" : "deactivate"} "{firstName} {lastName}"?
            </TCEDialog>
        </>
    )
};

export default CanvasserItem;